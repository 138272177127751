import React, { useEffect, useState } from "react";

import styles from "./CustomerRiskProfile.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { TextElement } from "../../componenets";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { useNavigate } from "react-router-dom";

import { UserRoleType } from "../../redux/authSlice";

import ApproverViewLayout from "../../componenets/ApproverViewLayout/ApproverViewLayout";
import RiskAnalysisCard from "../../componenets/RiskAnalysisCard/RiskAnalysisCard";
import DisplayResponseContainer from "../../componenets/CampaignReports/components/DisplayResponseContainer/DisplayResponseContainer";
import GeneratedActiveOffers from "../../componenets/GeneratedActiveOffers/GeneratedActiveOffers";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import useRiskProfile from "./useRiskProfile";

interface Props {
  userRole: UserRoleType;
}

const CustomerRiskProfile: React.FC<Props> = (props) => {
  const { userRole } = props;
  const dispatch = useAppDispatch();
  const { campaigns } = useAppSelector((state) => state.campaignData);
  const navigate = useNavigate();

  const [showGeneratedOffers, setShowGeneratedOffers] = useState(false);

  const { fetchRiskProfile, loading, riskProfiles } = useRiskProfile();

  useEffect(() => {
    console.log("riskProfiles", riskProfiles);
  }, [riskProfiles]);

  const handleOfferContent = () => {
    navigate(ROUTE_DEFINITIONS.CUSTOMER_RISK_PROFILE_CONTENT.path);
  };

  return (
    <MarkerterViewLayout
      header={
        <TextElement className={styles["header-text"]}>
          <button
            onClick={() => {
              navigate(ROUTE_DEFINITIONS.CUSTOMER_STAGE_INSIDE.path);
            }}
            className={styles["back-link"]}
          >
            Back to Main Funnel
          </button>
          <br />
          Customer Risk Profiles
        </TextElement>
      }
    >
      <div className={styles["container"]}>
        <DisplayResponseContainer
          iconType="fast-ninja"
          children={
            <TextElement className={styles["hot-insight"]}>
              Hey there! I’ve taken a look at your customer data and come up
              with some risk profiles for your 5G customers. By breaking them
              down into High-Risk, Medium-Risk, and Low-Risk categories, we can
              figure out exactly what they need to stay happy and loyal.
            </TextElement>
          }
        />
        <div className={styles["risk-analysis-container"]}>
          <RiskAnalysisCard
            riskType="alert"
            headerText="High Risk Customers"
            customerCount={125}
            engagementScore="Less than 20%"
            serviceComplaints=">5 complaints in the last 6 months"
            satisfactionScore="Below 3 out of 10"
            lateBillPayments=">3 in the last 6 months"
            onClickGenerateOffers={() => {
              setShowGeneratedOffers(true);
              fetchRiskProfile("High Risk");
            }}
          />

          {/* Medium Risk Card */}
          <RiskAnalysisCard
            riskType="warning"
            headerText="Medium Risk Customers"
            customerCount={125}
            engagementScore="Less than 20%"
            serviceComplaints="Less than 5 complaints in the last 6 months"
            satisfactionScore="Below 3 out of 10"
            lateBillPayments="Less than 3 in the last 6 months"
            onClickGenerateOffers={() => {
              setShowGeneratedOffers(true);
              fetchRiskProfile("Medium Risk");
            }}
          />

          {/* Low Risk Card */}
          <RiskAnalysisCard
            riskType="success"
            headerText="Low Risk Customers"
            customerCount={125}
            engagementScore="Less than 20%"
            serviceComplaints=">5 complaints in the last 6 months"
            satisfactionScore="Below 3 out of 10"
            lateBillPayments=">3 in the last 6 months"
            onClickGenerateOffers={() => {  
              setShowGeneratedOffers(true);
              fetchRiskProfile("Low Risk");
            }}
          />
        </div>
        {showGeneratedOffers && (
          <GeneratedActiveOffers
            offers={riskProfiles?.map((r) => ({
              heading: r.offer,
              text: r.offerDetails,
              onClick: () => {},
            }))}
            loading={loading}
          />
        )}
      </div>
    </MarkerterViewLayout>
  );
};

export default CustomerRiskProfile;
