import React from "react";

const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect width="20" height="20" rx="10" fill="#EB6301"/>
<path d="M16 5.875L7.75 14.125L4 10.375" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 5.875L7.75 14.125L4 10.375" stroke="#FFF6EB" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>




    
  );
};

export default CheckIcon;
