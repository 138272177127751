import React, { useState, useEffect, useRef } from "react";
import styles from "./SideBar.module.scss";
import classNames from "classnames";
import ErikoText from "../../icons/ErikoText";
import { NinjaIcon, PlusIcon } from "../../icons";
import TextElement from "../TextElement/TextElement";
import Button from "../Button/Button";
import NotificationIcon from "../../icons/NotificationIcon";

import BrandingList from "../BrandingList/BrandingList";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { SidebarItem } from "../../enums";
import MyTeamIcon from "../../icons/MyTeamIcon";
import LogoutIcon from "../../icons/LogoutIcon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logout, UserRoleType } from "../../redux/authSlice";
import { MdDashboard, MdOutlineBrokenImage } from "react-icons/md";
import { setSelectedCampaign } from "../../redux/campaignPinningSlice";
import InsightsIcon from "../../icons/InsightsIcon";
import StrategyIcon from "../../icons/StrategyIcon";
import { CiSettings } from "react-icons/ci";

import NotificationsSideIcon from "./NotificationsSideIcon";
import ConnectingChannelIcon from "../../icons/ConnectingChannelIcon";
import CampaignPlansIcon from "../../icons/MarketingCampaignsIcon";
import SalesStrategiesIcon from "../../icons/SalesStrategiesIcon";
import ServiceDeliveryDashboardIcon from "../../icons/ServiceDeliveryDashboardIcon";
import RetentionCampaignsIcon from "../../icons/RetentionCampaignsIcon";
import UpsellCrossSellCampaignsIcon from "../../icons/UpsellCrossSellCampaignsIcon";
import CompanyProfileIcon from "../../icons/CompanyProfileIcon";
import PipelineConfigurationIcon from "../../icons/PipelineConfigurationIcon";
import { updateDropDownSections } from "../../redux/sideBarSlice";
import MainFunnelIcon from "../../icons/MainFunnelIcon";
import MarketingDashboardIcon from "../../icons/MarketingDashboardIcon";
import SalesDashboardIcon from "../../icons/SalesDashboardIcon";
import MarketingCampaignsIcon from "../../icons/MarketingCampaignsIcon";

export type ActiveMarketerSideTabType =
  | "campaigns"
  | "notifications"
  | "brand-identity"
  | "style-guide"
  | "assets";

interface SideBarProps {
  className?: string;
  activeSideTab?: ActiveMarketerSideTabType;
}

const SideBar: React.FC<SideBarProps> = (props) => {
  const { className, activeSideTab = "campaigns" } = props;

  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [items, setItems] = useState<SidebarItem[]>([]);

  const [activeItem, setActiveItem] = useState<SidebarItem | null>(null);

  const dispatch = useAppDispatch();

  const { loggedInUser } = useAppSelector((state) => state.auth);
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const [homeLink, setHomeLink] = useState<string | null>();
  const { dropDownSections } = useAppSelector((state) => state.sideBar);

  useEffect(() => {
    if (loggedInUser?.role == UserRoleType.MARKETING) {
      setHomeLink(ROUTE_DEFINITIONS.HOME.path);
    } else if (
      loggedInUser?.role == UserRoleType.PRIMARY_APPROVER ||
      loggedInUser?.role == UserRoleType.SECONDARY_APPROVER
    ) {
      setHomeLink(ROUTE_DEFINITIONS.APPROVER_CAMPAGINS_LIST.path);
    } else if (loggedInUser?.role == UserRoleType.CMO) {
      setHomeLink(ROUTE_DEFINITIONS.CMO_HOME.path);
    }
  }, [loggedInUser]);

  useEffect(() => {
    const approverItems = [
      SidebarItem.LOGO,
      SidebarItem.AVATAR,
      SidebarItem.MARKETING_CHANNELS,
      SidebarItem.NOTIFICATIONS,
      SidebarItem.COMPANY_PROFILE,
    ];

    const marketerItems = [
      SidebarItem.LOGO,
      SidebarItem.AVATAR,

      SidebarItem.SALES_CAMPAIGNS,
      SidebarItem.SALES_DASHBOARD,

      SidebarItem.MAIN_FUNNEL,
      SidebarItem.MARKETING_CAMPAIGNS,
      SidebarItem.MARKETING_DASHBOARD,

      SidebarItem.CUSTOMER_SUCCESS_DASHBOARD,

      SidebarItem.NOTIFICATIONS,
      SidebarItem.MARKETING_CHANNELS,

      SidebarItem.RETENTION_CAMPAIGNS,
      SidebarItem.UPSELL_CROSS_SELL_CAMPAIGNS,
      SidebarItem.COMPANY_PROFILE,
      SidebarItem.PIPELINE_CONFIGURATION,
    ];

    const cmoItems = [
      SidebarItem.LOGO,
      SidebarItem.AVATAR,
      SidebarItem.ADD_TEAM_MEMBER,
      SidebarItem.NOTIFICATIONS,
      SidebarItem.MY_TEAM,
    ];

    switch (location.pathname) {
      case ROUTE_DEFINITIONS.LOGIN.path:
        setItems([]);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.HOME.path:
        setItems(marketerItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CAMPAGINS_LIST.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.NOTIFICATIONS.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.NOTIFICATIONS);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MY_BRANDING.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.COMPANY_PROFILE);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_DATA.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MARKETER_CREATE_VALUE.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MARKETER_IDENTIFY_VALUE.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.MARKETER_REALIZE.path:
        setItems(marketerItems);
        setActiveItem(null);
        setHomeLink(ROUTE_DEFINITIONS.HOME.path);
        break;

      case ROUTE_DEFINITIONS.CMO_HOME.path:
        setItems(cmoItems);
        // setActiveItem(SidebarItem.MARKETING_CAMPAIGNS);
        break;

      case ROUTE_DEFINITIONS.MY_TEAM_MEMBER.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.MY_TEAM);
        break;

      case ROUTE_DEFINITIONS.ADD_TEAM_MEMBER.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.ADD_TEAM_MEMBER);
        break;

      case ROUTE_DEFINITIONS.CMO_NOTIFICATION.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.NOTIFICATIONS);
        break;

      case ROUTE_DEFINITIONS.CMO_BRANDING.path:
        setItems(cmoItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.APPROVER_CAMPAGINS_LIST.path:
        setItems(approverItems);
        setActiveItem(SidebarItem.MARKETING_CAMPAIGNS);
        break;

      case ROUTE_DEFINITIONS.APPROVER_BRANDING.path:
        setItems(approverItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.APPROVER_CAMPAGIN_DATA.path:
        setItems(approverItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.APPROVER_NOTIFICATION.path:
        setItems(approverItems);
        setActiveItem(SidebarItem.NOTIFICATIONS);
        break;

      case ROUTE_DEFINITIONS.MARKETER_ACCOUNT.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.AVATAR);
        break;

      case ROUTE_DEFINITIONS.APPROVER_ACCOUNT.path:
        setItems(approverItems);
        setActiveItem(SidebarItem.AVATAR);
        break;

      case ROUTE_DEFINITIONS.CMO_ACCOUNT.path:
        setItems(cmoItems);
        setActiveItem(SidebarItem.AVATAR);
        break;

      case ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.MARKETING_CAMPAIGNS);
        break;

      case ROUTE_DEFINITIONS.APPROVER_CAMPAIGN_PLAN_LIST.path:
        setItems(approverItems);
        setActiveItem(SidebarItem.MARKETING_CAMPAIGNS);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CHANNEL_CONNECTIONS.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.MARKETING_CHANNELS);
        break;

      case ROUTE_DEFINITIONS.CUSTOMER_RISK_PROFILE.path:
        setItems(marketerItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.RETAIN_CUSTOMER.path:
        setItems(marketerItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.SALES_STRATEGIES.path:
        setItems(marketerItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.SALES_QUALIFIED_LEAD_INSIGHTS.path:
        setItems(marketerItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.SALES_QUALIFIED_OPPORTUNITIES.path:
        setItems(marketerItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.DRIVE_CONSUMPION.path:
        setItems(marketerItems);
        setActiveItem(null);
        break;

      case ROUTE_DEFINITIONS.RETENTION_CAMPAIGNS.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.RETENTION_CAMPAIGNS);
        break;

      case ROUTE_DEFINITIONS.UPSELL_CROSS_SELL_CAMPAIGNS.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.UPSELL_CROSS_SELL_CAMPAIGNS);
        break;

      case ROUTE_DEFINITIONS.MARKETING_PIPELINE_CONFIGURATION.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.PIPELINE_CONFIGURATION);
        break;

      case ROUTE_DEFINITIONS.MARKETING_CAMPAIGN_DASHBOARD.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.MARKETING_DASHBOARD);
        break;

      case ROUTE_DEFINITIONS.SALES_CAMPAIGN_DASHBOARD.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.SALES_DASHBOARD);
        break;

      case ROUTE_DEFINITIONS.CUSTOMER_SUCCESS_DASHBOARD.path:
        setItems(marketerItems);
        setActiveItem(SidebarItem.CUSTOMER_SUCCESS_DASHBOARD);
        break;

      default:
        throw `The current path '${location.pathname}' for the sidebar is not handled!`;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(e.target as Node)) {
      setIsExpanded(false);
    }
  };

  if (!items.length) {
    return <></>;
  }

  return (
    <div
      className={classNames(
        styles["container"],
        styles[isExpanded ? "expand" : "collapse"],
        className
      )}
      ref={sidebarRef}
    >
      {isExpanded && (
        <div className={styles["expand-sidebar"]}>
          {items.includes(SidebarItem.LOGO) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["logo-container"]
              )}
              onClick={() => {
                if (homeLink) navigate(homeLink);
              }}
            >
              <NinjaIcon className={styles["logo"]} />
              <ErikoText className={styles["text-icon"]} />
            </div>
          )}

          {items.includes(SidebarItem.AVATAR) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["user-info"]
              )}
              onClick={() => {
                if (loggedInUser?.role == UserRoleType.MARKETING) {
                  navigate(ROUTE_DEFINITIONS.MARKETER_ACCOUNT.path);
                } else if (
                  loggedInUser?.role == UserRoleType.PRIMARY_APPROVER ||
                  loggedInUser?.role == UserRoleType.SECONDARY_APPROVER
                ) {
                  navigate(ROUTE_DEFINITIONS.APPROVER_ACCOUNT.path);
                } else if (loggedInUser?.role == UserRoleType.CMO) {
                  navigate(ROUTE_DEFINITIONS.CMO_ACCOUNT.path);
                }
              }}
            >
              <img
                className={styles["user-img"]}
                src="/avatar.png"
                alt="profile"
              />
              <div className={styles["meta-data"]}>
                <TextElement className={styles["user-name"]}>
                  Sabeh Hassan
                </TextElement>
                <TextElement className={styles["user-email"]}>
                  {loggedInUser?.email}
                </TextElement>
              </div>
            </div>
          )}

          {items.includes(SidebarItem.MAIN_FUNNEL) && (
            <div className={styles["sidebar-item"]}>
              <Button
                className={styles["campaign-btn"]}
                onClick={() => {
                  dispatch(setSelectedCampaign(""));
                  navigate(ROUTE_DEFINITIONS.HOME.path);
                }}
              >
                <MainFunnelIcon className={styles["plus-icon"]} />
                <TextElement>Main Funnel</TextElement>
              </Button>
            </div>
          )}

          {items.includes(SidebarItem.ADD_TEAM_MEMBER) && (
            <div
              className={styles["sidebar-item"]}
              onClick={() => navigate(ROUTE_DEFINITIONS.ADD_TEAM_MEMBER.path)}
            >
              <Button className={styles["team-member-btn"]}>
                <PlusIcon className={styles["icon"]} />
                <TextElement>Add Team Member</TextElement>
              </Button>
            </div>
          )}

          <button
            className={styles["dropdown-menu"]}
            onClick={() => {
              dispatch(
                updateDropDownSections({
                  ...dropDownSections,
                  marketing: !dropDownSections?.marketing,
                })
              );
            }}
          >
            <TextElement>Marketing</TextElement>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles["updown-arrow"]}
              style={{
                transform: dropDownSections?.marketing
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <path
                d="M1.175 0.412403L5 4.22907L8.825 0.412403L10 1.5874L5 6.5874L0 1.5874L1.175 0.412403Z"
                fill="#02285E"
              />
            </svg>
          </button>

          {dropDownSections.marketing && (
            <div className={styles["marketing-section"]}>
              {items.includes(SidebarItem.MARKETING_CAMPAIGNS) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.MARKETING_CAMPAIGNS &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={() => {
                    if (loggedInUser?.role == UserRoleType.MARKETING) {
                      navigate(
                        ROUTE_DEFINITIONS.MARKETER_CAMPAIGN_PLAN_LIST.path
                      );
                    } else if (
                      loggedInUser?.role == UserRoleType.PRIMARY_APPROVER ||
                      loggedInUser?.role == UserRoleType.SECONDARY_APPROVER
                    ) {
                      navigate(
                        ROUTE_DEFINITIONS.APPROVER_CAMPAIGN_PLAN_LIST.path
                      );
                    } else if (loggedInUser?.role == UserRoleType.CMO) {
                      navigate(ROUTE_DEFINITIONS.CMO_HOME.path);
                    }
                  }}
                >
                  <CampaignPlansIcon className={styles["icon"]} />
                  <TextElement>Marketing Campaigns</TextElement>
                </div>
              )}

              {items.includes(SidebarItem.MARKETING_DASHBOARD) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.MARKETING_DASHBOARD &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={() => {
                    navigate(
                      ROUTE_DEFINITIONS.MARKETING_CAMPAIGN_DASHBOARD.path
                    );
                  }}
                >
                  <MarketingDashboardIcon className={styles["icon"]} />
                  <TextElement>Marketing Dashboard</TextElement>
                </div>
              )}
            </div>
          )}

          <button
            className={styles["dropdown-menu"]}
            onClick={() => {
              dispatch(
                updateDropDownSections({
                  ...dropDownSections,
                  sales: !dropDownSections?.sales,
                })
              );
            }}
          >
            <TextElement>Sales</TextElement>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles["updown-arrow"]}
              style={{
                transform: dropDownSections?.sales
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <path
                d="M1.175 0.412403L5 4.22907L8.825 0.412403L10 1.5874L5 6.5874L0 1.5874L1.175 0.412403Z"
                fill="#02285E"
              />
            </svg>
          </button>

          {dropDownSections.sales && (
            <div className={styles["sales-section"]}>
              {loggedInUser?.role === UserRoleType.MARKETING && (
                <div className={styles["divider"]}></div>
              )}

              {items.includes(SidebarItem.SALES_DASHBOARD) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.SALES_DASHBOARD &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={() => {
                    navigate(ROUTE_DEFINITIONS.SALES_CAMPAIGN_DASHBOARD.path);
                  }}
                >
                  <SalesDashboardIcon className={styles["icon"]} />
                  <TextElement>Sales Dashboard</TextElement>
                </div>
              )} 

              {items.includes(SidebarItem.SALES_CAMPAIGNS) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.SALES_CAMPAIGNS &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={() => {
                    navigate(ROUTE_DEFINITIONS.SALES_STRATEGIES.path);
                  }}
                >
                  <SalesStrategiesIcon className={styles["icon"]} />
                  <TextElement>Sales Campaigns</TextElement>
                </div>
              )}
            </div>
          )}

          <button
            className={styles["dropdown-menu"]}
            onClick={() => {
              dispatch(
                updateDropDownSections({
                  ...dropDownSections,
                  customer: !dropDownSections?.customer,
                })
              );
            }}
          >
            <TextElement>Customer</TextElement>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles["updown-arrow"]}
              style={{
                transform: dropDownSections?.customer
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <path
                d="M1.175 0.412403L5 4.22907L8.825 0.412403L10 1.5874L5 6.5874L0 1.5874L1.175 0.412403Z"
                fill="#02285E"
              />
            </svg>
          </button>

          {dropDownSections.customer && (
            <div className={styles["customer-section"]}>
              {loggedInUser?.role === UserRoleType.MARKETING && (
                <div className={styles["divider"]}></div>
              )}

              {items.includes(SidebarItem.CUSTOMER_SUCCESS_DASHBOARD) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.CUSTOMER_SUCCESS_DASHBOARD &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={() => {
                    navigate(ROUTE_DEFINITIONS.CUSTOMER_SUCCESS_DASHBOARD.path);
                  }}
                >
                  <ServiceDeliveryDashboardIcon className={styles["icon"]} />
                  <TextElement>Customer Success Dashboard</TextElement>
                </div>
              )}

              {items.includes(SidebarItem.RETENTION_CAMPAIGNS) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.RETENTION_CAMPAIGNS &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={() => {
                    navigate(ROUTE_DEFINITIONS.RETENTION_CAMPAIGNS.path);
                  }}
                >
                  <RetentionCampaignsIcon className={styles["icon"]} />
                  <TextElement>Retention Campaigns</TextElement>
                </div>
              )}

              {items.includes(SidebarItem.UPSELL_CROSS_SELL_CAMPAIGNS) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.UPSELL_CROSS_SELL_CAMPAIGNS &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={() => {
                    navigate(
                      ROUTE_DEFINITIONS.UPSELL_CROSS_SELL_CAMPAIGNS.path
                    );
                  }}
                >
                  <UpsellCrossSellCampaignsIcon className={styles["icon"]} />
                  <TextElement>Upsell/Cross-sell Campaigns</TextElement>
                </div>
              )}
            </div>
          )}

          <div className={styles["operational-section"]}>
            {loggedInUser?.role === UserRoleType.MARKETING && (
              <div className={styles["divider"]}></div>
            )}

            {items.includes(SidebarItem.NOTIFICATIONS) && (
              <div
                className={classNames(
                  styles["sidebar-item"],
                  styles["sidebar-item-hover"],
                  activeItem === SidebarItem.NOTIFICATIONS &&
                    styles["sidebar-item-active"]
                )}
                onClick={() => {
                  if (loggedInUser?.role == UserRoleType.MARKETING) {
                    navigate(ROUTE_DEFINITIONS.NOTIFICATIONS.path);
                  } else if (loggedInUser?.role == UserRoleType.CMO) {
                    navigate(ROUTE_DEFINITIONS.CMO_NOTIFICATION.path);
                  } else if (
                    loggedInUser?.role == UserRoleType.PRIMARY_APPROVER ||
                    loggedInUser?.role == UserRoleType.SECONDARY_APPROVER
                  ) {
                    navigate(ROUTE_DEFINITIONS.APPROVER_NOTIFICATION.path);
                  }
                }}
              >
                <NotificationIcon className={styles["icon"]} />
                <TextElement>Notifications</TextElement>
              </div>
            )}

            {items.includes(SidebarItem.COMPANY_PROFILE) && (
              <div
                className={classNames(
                  styles["sidebar-item"],
                  styles["sidebar-item-hover"],
                  activeItem === SidebarItem.COMPANY_PROFILE &&
                    styles["sidebar-item-active"]
                )}
                onClick={() => {
                  navigate(ROUTE_DEFINITIONS.MY_BRANDING.path);
                }}
              >
                <CompanyProfileIcon className={styles["icon"]} />
                <TextElement>Company Profile</TextElement>
              </div>
            )}

            {items.includes(SidebarItem.MARKETING_CHANNELS) && (
              <div
                className={classNames(
                  styles["sidebar-item"],
                  styles["sidebar-item-hover"],
                  activeItem === SidebarItem.MARKETING_CHANNELS &&
                    styles["sidebar-item-active"]
                )}
                onClick={() => {
                  navigate(
                    ROUTE_DEFINITIONS.MARKETING_CHANNEL_CONNECTIONS.path
                  );
                }}
              >
                <ConnectingChannelIcon className={styles["icon"]} />
                <TextElement>Marketing Channels</TextElement>
              </div>
            )}

            {items.includes(SidebarItem.PIPELINE_CONFIGURATION) && (
              <div
                className={classNames(
                  styles["sidebar-item"],
                  styles["sidebar-item-hover"],
                  activeItem === SidebarItem.PIPELINE_CONFIGURATION &&
                    styles["sidebar-item-active"]
                )}
                onClick={() => {
                  navigate(
                    ROUTE_DEFINITIONS.MARKETING_PIPELINE_CONFIGURATION.path
                  );
                }}
              >
                <PipelineConfigurationIcon className={styles["icon"]} />
                <TextElement>Pipeline Configuration</TextElement>
              </div>
            )}

            <button
              className={classNames(styles["sidebar-item"])}
              onClick={() => {
                dispatch(logout());
                navigate(ROUTE_DEFINITIONS.LOGIN.path);
              }}
            >
              <LogoutIcon className={styles["icon"]} />
              <TextElement>Logout</TextElement>
            </button>
          </div>
        </div>
      )}

      {!isExpanded && (
        <div className={styles["collapse-sidebar"]}>
          {items.includes(SidebarItem.LOGO) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["logo-container"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <NinjaIcon className={styles["logo"]} />
            </div>
          )}

          {items.includes(SidebarItem.AVATAR) && (
            <div
              className={classNames(
                styles["sidebar-item"],
                styles["user-info"]
              )}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <img
                className={styles["user-img"]}
                src="/avatar.png"
                alt="profile"
              />
            </div>
          )}

          {items.includes(SidebarItem.MAIN_FUNNEL) && (
            <div
              className={styles["sidebar-item"]}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <Button className={styles["campaign-btn"]}>
                <MainFunnelIcon className={styles["plus-icon"]} />
              </Button>
            </div>
          )}

          {items.includes(SidebarItem.ADD_TEAM_MEMBER) && (
            <div
              className={styles["sidebar-item"]}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);

                navigate(ROUTE_DEFINITIONS.ADD_TEAM_MEMBER.path);
              }}
            >
              <Button className={styles["team-member-btn"]}>
                <PlusIcon />
              </Button>
            </div>
          )}

          <button
            className={styles["dropdown-menu"]}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(true);
            }}
          >
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles["updown-arrow"]}
              style={{
                transform: dropDownSections?.marketing
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <path
                d="M1.175 0.412403L5 4.22907L8.825 0.412403L10 1.5874L5 6.5874L0 1.5874L1.175 0.412403Z"
                fill="#02285E"
              />
            </svg>
          </button>

          {dropDownSections.marketing && (
            <div className={styles["marketing-section"]}>
              {items.includes(SidebarItem.MARKETING_CAMPAIGNS) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.MARKETING_CAMPAIGNS &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(true);
                  }}
                >
                  <MarketingCampaignsIcon className={styles["icon"]} />
                </div>
              )}

              {items.includes(SidebarItem.MARKETING_DASHBOARD) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.MARKETING_DASHBOARD &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(true);
                  }}
                >
                  <MarketingDashboardIcon className={styles["icon"]} />
                </div>
              )}
            </div>
          )}

          <button
            className={styles["dropdown-menu"]}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(true);
            }}
          >
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles["updown-arrow"]}
              style={{
                transform: dropDownSections?.sales
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <path
                d="M1.175 0.412403L5 4.22907L8.825 0.412403L10 1.5874L5 6.5874L0 1.5874L1.175 0.412403Z"
                fill="#02285E"
              />
            </svg>
          </button>

          {dropDownSections?.sales && (
            <div className={styles["sales-section"]}>
              {loggedInUser?.role === UserRoleType.MARKETING && (
                <div className={styles["divider"]}></div>
              )}

              {items.includes(SidebarItem.SALES_DASHBOARD) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.SALES_DASHBOARD &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(true);
                  }}
                >
                  <SalesDashboardIcon className={styles["icon"]} />
                </div>
              )}

              {items.includes(SidebarItem.SALES_CAMPAIGNS) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.SALES_CAMPAIGNS &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(true);
                  }}
                >
                  <SalesStrategiesIcon className={styles["icon"]} />
                </div>
              )}
            </div>
          )}

          <button
            className={styles["dropdown-menu"]}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(true);
            }}
          >
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles["updown-arrow"]}
              style={{
                transform: dropDownSections?.customer
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <path
                d="M1.175 0.412403L5 4.22907L8.825 0.412403L10 1.5874L5 6.5874L0 1.5874L1.175 0.412403Z"
                fill="#02285E"
              />
            </svg>
          </button>

          {dropDownSections?.customer && (
            <div className={styles["customer-section"]}>
              {loggedInUser?.role === UserRoleType.MARKETING && (
                <div className={styles["divider"]}></div>
              )}

              {items.includes(SidebarItem.CUSTOMER_SUCCESS_DASHBOARD) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.CUSTOMER_SUCCESS_DASHBOARD &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(true);
                  }}
                >
                  <ServiceDeliveryDashboardIcon className={styles["icon"]} />
                </div>
              )}

              {items.includes(SidebarItem.RETENTION_CAMPAIGNS) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.RETENTION_CAMPAIGNS &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(true);
                  }}
                >
                  <RetentionCampaignsIcon className={styles["icon"]} />
                </div>
              )}

              {items.includes(SidebarItem.UPSELL_CROSS_SELL_CAMPAIGNS) && (
                <div
                  className={classNames(
                    styles["sidebar-item"],
                    styles["sidebar-item-hover"],
                    activeItem === SidebarItem.UPSELL_CROSS_SELL_CAMPAIGNS &&
                      styles["sidebar-item-active"]
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(true);
                  }}
                >
                  <UpsellCrossSellCampaignsIcon className={styles["icon"]} />
                </div>
              )}
            </div>
          )}

          <div className={styles["operational-section"]}>
            {loggedInUser?.role === UserRoleType.MARKETING && (
              <div className={styles["divider"]}></div>
            )}

            {items.includes(SidebarItem.NOTIFICATIONS) && (
              <div
                className={classNames(
                  styles["sidebar-item"],
                  styles["sidebar-item-hover"],
                  activeItem === SidebarItem.NOTIFICATIONS &&
                    styles["sidebar-item-active"]
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(true);
                }}
              >
                <NotificationIcon className={styles["icon"]} />
              </div>
            )}

            {items.includes(SidebarItem.COMPANY_PROFILE) && (
              <div
                className={classNames(
                  styles["sidebar-item"],
                  styles["sidebar-item-hover"],
                  activeItem === SidebarItem.COMPANY_PROFILE &&
                    styles["sidebar-item-active"]
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(true);
                }}
              >
                <CompanyProfileIcon className={styles["icon"]} />
              </div>
            )}

            {items.includes(SidebarItem.MARKETING_CHANNELS) && (
              <div
                className={classNames(
                  styles["sidebar-item"],
                  styles["sidebar-item-hover"],
                  activeItem === SidebarItem.MARKETING_CHANNELS &&
                    styles["sidebar-item-active"]
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(true);
                }}
              >
                <ConnectingChannelIcon className={styles["icon"]} />
              </div>
            )}

            {items.includes(SidebarItem.PIPELINE_CONFIGURATION) && (
              <div
                className={classNames(
                  styles["sidebar-item"],
                  styles["sidebar-item-hover"],
                  activeItem === SidebarItem.PIPELINE_CONFIGURATION &&
                    styles["sidebar-item-active"]
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsExpanded(true);
                }}
              >
                <PipelineConfigurationIcon className={styles["icon"]} />
              </div>
            )}

            <button
              className={classNames(styles["sidebar-item"])}
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
            >
              <LogoutIcon className={styles["icon"]} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideBar;
