import HomeIcon from "../../icons/HomeIcon";
import Accordion from "./components/AccordionItem";
import StageCard from "./components/StageCard";
import OverviewCard from "./components/OverviewCard";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";

const cards = [
  {
    title: "Objective",
    content: (
      <p>
        To re-engage MQLs who have become inactive, reminding them of the value
        your services provide and guiding them back towards consideration for
        conversion.
      </p>
    ),
  },
  {
    title: "Target Audience",
    content: (
      <p>
        MQLs that have not interacted with your communications or services for a
        specified period (e.g., 30-60 days).
      </p>
    ),
  },
  {
    title: "Journey Overview",
    content: (
      <>
        {" "}
        <p>This nurturing journey consists of three key stages:</p>
        <ol style={{ listStyle: "auto", padding: "revert" }}>
          <li>Reignite Interest</li>
          <li>Address Pain Points</li>
          <li>Invite for Re-engagement</li>
        </ol>
      </>
    ),
  },
];

const exitCards = [
  {
    title: "Exit Conditions",
    content: (
      <>
        <p>The journey concludes when:</p>
        <ul style={{ listStyle: "disc", padding: "revert" }}>
          <li>A consultation is booked with the MQL.</li>
          <li>
            If there is no engagement after 60 days from the last interaction,
            consider moving them to a more general re-engagement campaign.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: "Metrics for Success",
    content: (
      <>
        <p>
          <b>Monitor the following key performance indicators:</b>
        </p>
        <ul style={{ listStyle: "disc", padding: "revert" }}>
          <li>Email open and click rates for the nurturing sequence.</li>
          <li>
            Number of consultations scheduled as a result of the nurture
            journey.
          </li>
          <li>
            Feedback from MQLs on the communications and offerings presented.
          </li>
          <li>Conversion rate of MQLs to customers post-nurturing.</li>
        </ul>
      </>
    ),
  },
  {
    title: "Metrics for Success",
    content: (
      <>
        <ul style={{ listStyle: "disc", padding: "revert" }}>
          <li>
            Regularly analyze engagement data and feedback to refine messaging,
            timing, and types of content shared.
          </li>
          <li>
            Experiment with different offers or incentives based on MQL behavior
            and preferences to maximize engagement.
          </li>
        </ul>
      </>
    ),
  },
];

const handlePinToggle = (isPinned: boolean) => {
  console.log("Card pinned:", isPinned);
};

const accordionData = [
  {
    title: "Strategy Overview",
    content: (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {cards.map((card, index) => (
          <OverviewCard
            key={index}
            title={card.title}
            content={card.content}
            onPinClick={handlePinToggle}
          />
        ))}
      </div>
    ),
  },
  {
    title: "Stages and Actions",
    content: (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        <StageCard
          title="Stage 1: Reignite Interest"
          entryDescription="Triggered when an MQL is identified as having dropped off."
          actionDescription="Send an email inviting the customer to schedule a call."
          triggerDescription="The next stage is activated when the client clicks the CTA."
          subject="Let's Talk About Flexible Payment Options"
          body="Remind them of their interest and share exciting updates or new features of your services."
        />
        <StageCard
          title="Stage 2: Address Pain Points"
          entryDescription="Triggered when an MQL is identified as having dropped off."
          actionDescription="Send an email inviting the customer to schedule a call."
          triggerDescription="CTA_Clicked (Customer clicks the “Schedule a Call” button)."
          subject="Let's Talk About Flexible Payment Options"
          body="Remind them of their interest and share exciting updates or new features of your services."
        />
        <StageCard
          title="Stage 3: Invite for Re-engagement"
          entryDescription="Triggered when an MQL is identified as having dropped off."
          actionDescription="Send an email inviting the customer to schedule a call."
          triggerDescription="CTA_Clicked (Customer clicks the “Schedule a Call” button)."
          subject="Let's Talk About Flexible Payment Options"
          body="Remind them of their interest and share exciting updates or new features of your services."
        />
      </div>
    ),
  },
  {
    title: "Exit Conditions, Metric for Success and Improvements",
    content: (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {exitCards.map((card, index) => (
          <OverviewCard
            key={index}
            title={card.title}
            content={card.content}
            onPinClick={handlePinToggle}
          />
        ))}
      </div>
    ),
  },
];

const Campaigns = () => {
  const navigate = useNavigate();
  return (
    <div className="h-full">
      <div className="w-full bg-white py-5 px-4 border-b-2 flex items-center justify-between">
        <button className="flex items-center gap-2 hover:bg-gray-100 p-2 rounded-md">
          <HomeIcon />
          <span className="text-gray-700">Back</span>
        </button>
        <div>
          <p className="m-0">Nurture Campaign - Plan</p>
        </div>
        <div className="flex items-center gap-3">
          <button className="bg-blue-primary py-3 px-8 rounded-xl text-white hover:bg-blue-600 transition-colors duration-200">
            Submit for approval
          </button>
        </div>
      </div>
      <div className="flex h-[90vh]">
        <div className="min-w-[60px]  bg-[#FAFAFA]">
          <div
            className="p-3 bg-[#eb270119]
"
          >
            icon
            {/* <img src="" alt="" className="max-w-[20px] " /> */}
          </div>
        </div>
        <div className="w-full">
          <div className="mt-5 px-4 ">
            <div className="bg-[#FAFAFA]">
              <div className="bg-blue-primary text-white p-2 rounded-tl-md rounded-tr-md">
                <h2 className="uppercase font-bold text-base">
                  NURTURE CAMPAIGN PLAN
                </h2>
              </div>
              <div className="p-3">
                <h4 className="text-[#EB2701] font-bold text-2xl  mt-2">
                  Nurture Plan for Dropped Leads
                </h4>
              </div>

              <div className="w-full ">
                <Accordion items={accordionData} />
              </div>
            </div>
          </div>

          <div className="w-full my-2 px-4">
            <button
              onClick={() =>
                navigate(ROUTE_DEFINITIONS.CONTENT_JOURNEY_ONE.path)
              }
              className="bg-blue-primary py-3 px-8 rounded-xl text-white hover:bg-blue-600 transition-colors duration-200"
            >
              Build Nurture Journey
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Campaigns;
