import React, { useState, ReactNode } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

type AccordionItemProps = {
  title: ReactNode;
  content: ReactNode;
};

const AccordionItem = ({ title, content }: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border-0 w-full mt-2 bg-white">
      <button
        className="w-full flex justify-between items-center text-left p-4 border-b border-[#EB2701] hover:bg-gray-100 font-medium transition duration-300 ease-in-out border-0"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-blue-primary font-medium text-xl">{title}</span>
        <span className="ml-2">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </button>
      <div
        className={`overflow-hidden transition-max-height duration-500 ease-in-out mt-2 ${
          isOpen ? "h-full" : "h-0"
        }`}
      >
        {isOpen && <div className="p-2">{content}</div>}
      </div>
    </div>
  );
};

type AccordionProps = {
  items: {
    title: ReactNode;
    content: ReactNode;
  }[];
};

const Accordion = ({ items }: AccordionProps) => {
  return (
    <div className="w-full  border-0 rounded-lg">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default Accordion;
