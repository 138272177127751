import React, { useEffect } from "react";

import styles from "./MarketingDashboardReport.module.scss";

import TextElement from "../TextElement/TextElement";

import Metric from "../CampaignReports/components/Metric/Metric";
import Select from "../Select/Select";

import classNames from "classnames";
import { NinjaIcon } from "../../icons";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import GraphWrapper from "../Graph/GraphWrapper";

import useMarketingDashboardReport from "./useMarketingDashboardReport";
import { Context } from "chartjs-plugin-datalabels";
import { formatNumberStandard } from "../../lib/helpers";
import { DateCapturedValues } from "./types";
import { Chart } from "react-google-charts";
import CreateStrategyBox from "../CreateStrategyBox/CreateStrategyBox";
import { ROUTE_DEFINITIONS } from "../../pages/AppRoutes";
import { useAppDispatch } from "../../redux/hooks";
import { clearCampaignQueryAnalysis } from "../../redux/campaignPlanAnalysisQueryChat";
import { clearContentGenerationState } from "../../redux/contentGenerationSlice";

import {
  Campaign,
  CampaignStatusType,
  deleteCampaign,
  fetchCampaigns,
  setSelectedCampaign,
} from "../../redux/campaignPinningSlice";

type Props = {
  className?: string;
  onClick?: () => void;
};

const MarketingDashboardReport: React.FC<Props> = (props) => {
  const { className, onClick } = props;
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = React.useState("");
  const [selectedCampaignId, setSelectedCampaignId] = React.useState("");
  const [dateCaptured, setDateCaptured] =
    React.useState<DateCapturedValues>("");

  const dispatch = useAppDispatch();

  const {
    keyFindings,
    products,
    campaigns,
    costEngagementScatterData,
    contactsTrendData,
    performanceAcrossKeyMetrics,
    emailPerformanceData,
    landingPagePerformanceData,
    linkedInEngagementData,
    dateCapturedOptions,
  } = useMarketingDashboardReport(
    selectedProduct,
    selectedCampaignId,
    dateCaptured
  );

  return (
    <div
      className={classNames(styles["container"], className)}
      onClick={onClick}
    >
      <div className={styles["filters"]}>
        <Select
          options={
            products
              ? [
                  {
                    label: "Product",
                    value: "",
                  },
                  ...products.map((product) => ({
                    label: product.productName,
                    value: product.productId,
                  })),
                ]
              : []
          }
          value={selectedProduct}
          placeholderText="Product"
          onChange={(value) => setSelectedProduct(value as string)}
          buttonClassName={styles["select-button"]}
          className={styles["select-menu"]}
          icon={
            <svg
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.18336 4.08994C4.30573 5.63979 2.07291 5.63979 1.19528 4.08994C0.330244 2.56232 1.43378 0.669238 3.18932 0.669238C4.94485 0.669238 6.04839 2.56232 5.18336 4.08994Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Select
          buttonClassName={styles["select-button"]}
          className={styles["select-menu"]}
          options={
            campaigns
              ? [
                  {
                    label: "Campaign:All",
                    value: "",
                  },
                  ...campaigns.map((campaign) => ({
                    label: campaign.campaignName,
                    value: campaign.campaignId,
                  })),
                ]
              : []
          }
          value={selectedCampaignId}
          onChange={(value) => setSelectedCampaignId(value as string)}
          placeholderText="Campaign"
          icon={
            <svg
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.18336 4.08994C4.30573 5.63979 2.07291 5.63979 1.19528 4.08994C0.330244 2.56232 1.43378 0.669238 3.18932 0.669238C4.94485 0.669238 6.04839 2.56232 5.18336 4.08994Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
        <Select
          className={styles["select-menu"]}
          buttonClassName={styles["select-button"]}
          options={dateCapturedOptions.map((date) => ({
            label: date?.label,
            value: date?.value,
          }))}
          value={dateCaptured}
          onChange={(value) => {
            setDateCaptured(value as DateCapturedValues);
          }}
          placeholderText="Date Captured"
          icon={
            <svg
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.18336 4.08994C4.30573 5.63979 2.07291 5.63979 1.19528 4.08994C0.330244 2.56232 1.43378 0.669238 3.18932 0.669238C4.94485 0.669238 6.04839 2.56232 5.18336 4.08994Z"
                fill="#EB2701"
              />
            </svg>
          }
        />
      </div>
      <TextElement className={styles["sub-heading"]}>Key Findings</TextElement>
      <div className={classNames(styles["create-strategy-boxes"])}>
        <CreateStrategyBox
          value={`${keyFindings?.averageLeadDropOffRate?.toFixed(2)}%`}
          valueLabel="Average Lead Drop-Off Rate"
          createBtnText="Create Nurture Strategy"
          icon={
            <svg
              width="30"
              height="19"
              viewBox="0 0 30 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 18.2803L24.435 14.8453L17.115 7.52527L11.115 13.5253L0 2.39527L2.115 0.280273L11.115 9.28027L17.115 3.28027L26.565 12.7153L30 9.28027V18.2803H21Z"
                fill="white"
              />
            </svg>
          }
        />
        <CreateStrategyBox
          value={`${formatNumberStandard(keyFindings?.totalDormantMQLs)}`}
          valueLabel="Total Dormant MQLs"
          createBtnText="Create Outreach Strategy"
          icon={
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.885 9.28027L15 13.1653L11.115 9.28027L9 11.3953L12.885 15.2803L9 19.1653L11.115 21.2803L15 17.3953L18.885 21.2803L21 19.1653L17.115 15.2803L21 11.3953L18.885 9.28027ZM15 0.280273C6.705 0.280273 0 6.98527 0 15.2803C0 23.5753 6.705 30.2803 15 30.2803C23.295 30.2803 30 23.5753 30 15.2803C30 6.98527 23.295 0.280273 15 0.280273ZM15 27.2803C8.385 27.2803 3 21.8953 3 15.2803C3 8.66527 8.385 3.28027 15 3.28027C21.615 3.28027 27 8.66527 27 15.2803C27 21.8953 21.615 27.2803 15 27.2803Z"
                fill="white"
              />
            </svg>
          }
        />
        <CreateStrategyBox
          value={`${formatNumberStandard(keyFindings?.totalIdleContacts)}`}
          onClick={() => {
            dispatch(clearCampaignQueryAnalysis({}));
            dispatch(clearContentGenerationState());
            dispatch(setSelectedCampaign(""));
            navigate(ROUTE_DEFINITIONS.MARKETING_CAMPAGIN_BUILDER.path);
          }}
          valueLabel="Total Idle Contacts"
          createBtnText="Create Campaign"
          icon={
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 0.280273C6.7335 0.280273 0 7.01377 0 15.2803C0 23.5468 6.7335 30.2803 15 30.2803C23.2665 30.2803 30 23.5468 30 15.2803C30 7.01377 23.2665 0.280273 15 0.280273ZM15 3.28027C21.6452 3.28027 27 8.63509 27 15.2803C27 18.0599 26.0539 20.6061 24.4775 22.6367L7.64355 5.80273C9.67416 4.22638 12.2204 3.28027 15 3.28027ZM5.52246 7.92383L22.3564 24.7578C20.3258 26.3342 17.7796 27.2803 15 27.2803C8.35482 27.2803 3 21.9255 3 15.2803C3 12.5007 3.94611 9.95443 5.52246 7.92383Z"
                fill="white"
              />
            </svg>
          }
        />
      </div>

      <div className={styles["findings"]}>
        <Metric
          label="Total Contacts Generated"
          value={formatNumberStandard(keyFindings?.totalContactsGenerated)}
          className={styles["metric"]}
          icon={
            <svg
              width="15"
              height="13"
              viewBox="0 0 15 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 6.51777C9.57187 6.51777 11.25 5.0662 11.25 3.27402C11.25 1.48185 9.57187 0.0302734 7.5 0.0302734C5.42812 0.0302734 3.75 1.48185 3.75 3.27402C3.75 5.0662 5.42812 6.51777 7.5 6.51777ZM7.5 8.13965C4.99687 8.13965 0 9.22631 0 11.3834V13.0053H15V11.3834C15 9.22631 10.0031 8.13965 7.5 8.13965Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Total Engaged Contacts"
          value={formatNumberStandard(keyFindings?.totalEngagedContacts)}
          className={styles["metric"]}
          icon={
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7436 7.97005C19.9145 8.16241 20 8.39084 20 8.65534C20 8.91983 19.9145 9.14826 19.7436 9.34063L16.4336 12.731C16.2626 12.9234 16.0451 13.0196 15.7809 13.0196C15.5167 13.0196 15.2991 12.9234 15.1282 12.731L13.7995 11.3845C13.6131 11.1921 13.5198 10.9637 13.5198 10.6992C13.5198 10.4347 13.6131 10.2063 13.7995 10.0139C13.986 9.83756 14.2075 9.7494 14.4639 9.7494C14.7203 9.7494 14.9417 9.83756 15.1282 10.0139L15.7809 10.6872L18.4149 7.97005C18.6014 7.79371 18.8228 7.70555 19.0793 7.70555C19.3357 7.70555 19.5571 7.79371 19.7436 7.97005ZM8.69464 17.492L6.36364 15.3039C5.24476 14.2619 4.28516 13.3321 3.48485 12.5146C2.68454 11.6971 2.02409 10.9276 1.5035 10.2063C0.982906 9.4849 0.602176 8.78759 0.361305 8.11432C0.120435 7.44105 0 6.73572 0 5.99833C0 4.4915 0.48951 3.23714 1.46853 2.23525C2.44755 1.23336 3.66744 0.732422 5.1282 0.732422C5.93629 0.732422 6.70552 0.904746 7.4359 1.2494C8.16628 1.59404 8.79565 2.08697 9.32401 2.72818C9.85237 2.08697 10.4817 1.59404 11.2121 1.2494C11.9425 0.904746 12.7117 0.732422 13.5198 0.732422C14.8407 0.732422 15.9479 1.1452 16.8415 1.97075C17.735 2.79631 18.2906 3.76213 18.5082 4.86821C18.1974 4.73997 17.871 4.65981 17.5291 4.62775C17.1873 4.59569 16.8376 4.57966 16.4802 4.57966C15.1593 4.57966 13.9472 5.1287 12.8438 6.22676C11.7405 7.32483 11.1888 8.69942 11.1888 10.3505C11.1888 11.12 11.352 11.9014 11.6783 12.6949C12.0047 13.4884 12.4631 14.1336 13.0536 14.6306C12.7584 14.9031 12.3737 15.2518 11.8998 15.6766C11.4258 16.1014 11.0179 16.4741 10.676 16.7947L9.93007 17.492C9.75913 17.6523 9.55322 17.7324 9.31235 17.7324C9.07148 17.7324 8.86558 17.6523 8.69464 17.492Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Average Engagement Score"
          value={`${formatNumberStandard(
            keyFindings?.averageEngagementScore
          )}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8.87528C16 8.12885 15.4 7.51814 14.6667 7.51814H7.76L8.64 3.20242L8.66667 2.89028C8.66667 2.46956 8.49333 2.08956 8.22667 1.80456L7.17333 0.732422L0.586667 7.43671C0.226667 7.80314 0 8.31885 0 8.87528V17.6967C0 18.8231 0.893333 19.7324 2 19.7324H11C11.8267 19.7324 12.5333 19.2167 12.84 18.4974L15.8533 11.3181C15.9467 11.0874 16 10.8296 16 10.5717V8.87528Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Average Email Open Rate"
          value={`${formatNumberStandard(keyFindings?.averageEmailOpenRate)}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.04762 14.0658C9.04762 10.6258 11.8795 7.84353 15.381 7.84353C16.3581 7.84353 17.2719 8.06576 18.0952 8.44798V2.5102C18.0952 1.53242 17.281 0.732422 16.2857 0.732422H1.80952C0.814286 0.732422 0 1.53242 0 2.5102V13.1769C0 14.1546 0.814286 14.9546 1.80952 14.9546H9.12C9.07476 14.6613 9.04762 14.368 9.04762 14.0658ZM1.80952 2.5102L9.04762 6.95464L16.2857 2.5102V4.28798L9.04762 8.73242L1.80952 4.28798V2.5102ZM13.879 16.7324L10.6762 13.5858L11.9519 12.3324L13.87 14.2169L17.7062 10.448L19 11.7013L13.879 16.7324Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Average Email Click Rate"
          value={`${formatNumberStandard(keyFindings?.averageEmailClickRate)}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="11"
              height="18"
              viewBox="0 0 11 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0L11 10.08L5.68333 10.53L8.70833 17.1L6.69167 18L3.75833 11.34L0 14.85V0Z"
                fill="white"
              />
            </svg>
          }
        />
        <Metric
          label="Landing Page Conversion Rate"
          value={`${formatNumberStandard(
            keyFindings?.landingPageConversionRate
          )}%`}
          className={styles["metric"]}
          icon={
            <svg
              width="18"
              height="23"
              viewBox="0 0 18 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.3 17.7574C1.58333 16.9574 1.02083 16.0408 0.6125 15.0074C0.204167 13.9741 0 12.8824 0 11.7324C0 9.23242 0.875 7.10742 2.625 5.35742C4.375 3.60742 6.5 2.73242 9 2.73242V0.732422L14 4.48242L9 8.23242V6.23242C7.48333 6.23242 6.1875 6.76992 5.1125 7.84492C4.0375 8.91992 3.5 10.2158 3.5 11.7324C3.5 12.4991 3.64583 13.2158 3.9375 13.8824C4.22917 14.5491 4.625 15.1324 5.125 15.6324L2.3 17.7574ZM9 22.7324L4 18.9824L9 15.2324V17.2324C10.5167 17.2324 11.8125 16.6949 12.8875 15.6199C13.9625 14.5449 14.5 13.2491 14.5 11.7324C14.5 10.9658 14.3542 10.2491 14.0625 9.58242C13.7708 8.91576 13.375 8.33242 12.875 7.83242L15.7 5.70742C16.4167 6.50742 16.9792 7.42409 17.3875 8.45742C17.7958 9.49076 18 10.5824 18 11.7324C18 14.2324 17.125 16.3574 15.375 18.1074C13.625 19.8574 11.5 20.7324 9 20.7324V22.7324Z"
                fill="white"
              />
            </svg>
          }
        />
      </div>

      <div className={styles["three-column"]}>
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="ScatterChart"
              width="100%"
              height="100%"
              data={costEngagementScatterData}
              options={{
                chartArea: { width: "88%", height: "80%" },
                legend: "none",
                vAxis: { title: "Engagement Rate" },
                hAxis: { title: "Cost Per Engagement" },
              }}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Cost per Engagement vs. Engagement Rate"
        />
        <GraphWrapper
          graphComonent={
            <Chart
              chartType="BarChart"
              width="100%"
              height="100%"
              data={contactsTrendData}
              legendToggle
              options={{
                chartArea: { width: "90%", height: "80%" },
                colors: ["#EB2701", "#FC8A73", "#FFCFC6"],
                legend: {
                  position: "top",
                },
              }}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Converted, Engaged and Idle Contacts Trend"
        />

        <GraphWrapper
          graphComonent={
            <Chart
              chartType="AreaChart"
              width="100%"
              height="100%"
              data={performanceAcrossKeyMetrics}
              legendToggle
              options={{
                isStacked: true,
                colors: ["#FE5A3A", "#FC8A73", "#FFCFC6"],
                legend: {
                  position: "top",
                },
                chartArea: { width: "81%", height: "72%" },
              }}
            />
          }
          containerClassName={styles["graph-container"]}
          titleClassName={styles["sub-heading"]}
          title="Performance Across Key Metrics"
        />
      </div>
      <TextElement className={styles["sub-heading"]}>
        Marketing Channels Performance Overview
      </TextElement>

      <div className={styles["channels-container"]}>
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            Email Marketing
          </TextElement>
          <div className={classNames(styles["channel-metrics"])}>
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Email Sent"
              value={formatNumberStandard(keyFindings?.totalEmailsSent)}
              className={styles["metric"]}
              icon={
                <svg
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0.280273H2C1.175 0.280273 0.5075 0.955273 0.5075 1.78027L0.5 10.7803C0.5 11.6053 1.175 12.2803 2 12.2803H14C14.825 12.2803 15.5 11.6053 15.5 10.7803V1.78027C15.5 0.955273 14.825 0.280273 14 0.280273ZM14 3.28027L8 7.03027L2 3.28027V1.78027L8 5.53027L14 1.78027V3.28027Z"
                    fill="white"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Emails Opened"
              value={formatNumberStandard(keyFindings?.totalEmailsOpened)}
              className={styles["metric"]}
              icon={
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 10.2803C7.16667 7.70027 9.25333 5.61361 11.8333 5.61361C12.5533 5.61361 13.2267 5.78027 13.8333 6.06694V1.61361C13.8333 0.880273 13.2333 0.280273 12.5 0.280273H1.83333C1.1 0.280273 0.5 0.880273 0.5 1.61361V9.61361C0.5 10.3469 1.1 10.9469 1.83333 10.9469H7.22C7.18667 10.7269 7.16667 10.5069 7.16667 10.2803ZM1.83333 1.61361L7.16667 4.94694L12.5 1.61361V2.94694L7.16667 6.28027L1.83333 2.94694V1.61361ZM10.7267 12.2803L8.36667 9.92027L9.30667 8.98027L10.72 10.3936L13.5467 7.56694L14.5 8.50694L10.7267 12.2803Z"
                    fill="white"
                  />
                </svg>
              }
            />
          </div>
          <GraphWrapper
            graphComonent={
              <Chart
                chartType="BarChart"
                width="100%"
                height="100%"
                data={emailPerformanceData}
                legendToggle
                options={{
                  chartArea: { width: "90%", height: "80%" },
                  colors: ["#EB2701", "#FC8A73", "#FFCFC6"],
                  legend: {
                    position: "top",
                  },
                }}
              />
            }
            containerClassName={styles["graph-container"]}
            titleClassName={styles["sub-heading"]}
            title={
              <>
                Email Performance Trend <br />
                (Sent vs Opened vs Clicked)
              </>
            }
          />
        </div>
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            Landing Page
          </TextElement>
          <div className={classNames(styles["channel-metrics"])}>
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Total Landing Page Visits"
              value={formatNumberStandard(keyFindings?.totalLandingPageVisits)}
              className={styles["metric"]}
              icon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 15.7803C6.9625 15.7803 5.9875 15.5834 5.075 15.1896C4.1625 14.7959 3.36875 14.2615 2.69375 13.5865C2.01875 12.9115 1.48438 12.1178 1.09063 11.2053C0.696875 10.2928 0.5 9.31777 0.5 8.28027C0.5 7.24277 0.696875 6.26777 1.09063 5.35527C1.48438 4.44277 2.01875 3.64902 2.69375 2.97402C3.36875 2.29902 4.1625 1.76465 5.075 1.3709C5.9875 0.977148 6.9625 0.780273 8 0.780273C9.0375 0.780273 10.0125 0.977148 10.925 1.3709C11.8375 1.76465 12.6313 2.29902 13.3063 2.97402C13.9813 3.64902 14.5156 4.44277 14.9094 5.35527C15.3031 6.26777 15.5 7.24277 15.5 8.28027C15.5 9.31777 15.3031 10.2928 14.9094 11.2053C14.5156 12.1178 13.9813 12.9115 13.3063 13.5865C12.6313 14.2615 11.8375 14.7959 10.925 15.1896C10.0125 15.5834 9.0375 15.7803 8 15.7803ZM8 14.2803C9.675 14.2803 11.0938 13.699 12.2563 12.5365C13.4188 11.374 14 9.95527 14 8.28027C14 8.19277 13.9969 8.10215 13.9906 8.0084C13.9844 7.91465 13.9813 7.83652 13.9813 7.77402C13.9188 8.13652 13.75 8.43652 13.475 8.67402C13.2 8.91152 12.875 9.03027 12.5 9.03027H11C10.5875 9.03027 10.2344 8.8834 9.94063 8.58965C9.64688 8.2959 9.5 7.94277 9.5 7.53027V6.78027H6.5V5.28027C6.5 4.86777 6.64688 4.51465 6.94063 4.2209C7.23438 3.92715 7.5875 3.78027 8 3.78027H8.75C8.75 3.49277 8.82812 3.23965 8.98438 3.0209C9.14062 2.80215 9.33125 2.62402 9.55625 2.48652C9.30625 2.42402 9.05313 2.37402 8.79688 2.33652C8.54063 2.29902 8.275 2.28027 8 2.28027C6.325 2.28027 4.90625 2.86152 3.74375 4.02402C2.58125 5.18652 2 6.60527 2 8.28027H5.75C6.575 8.28027 7.28125 8.57402 7.86875 9.16152C8.45625 9.74902 8.75 10.4553 8.75 11.2803V12.0303H6.5V14.0928C6.75 14.1553 6.99688 14.2021 7.24063 14.2334C7.48438 14.2646 7.7375 14.2803 8 14.2803Z"
                    fill="white"
                  />
                </svg>
              }
            />
            <Metric
              iconContainerClassName={styles["icon-container"]}
              label="Average Landing Page Bounce Rate"
              value={`${formatNumberStandard(
                keyFindings?.averageLandingPageBounceRate
              )}%`}
              className={styles["metric"]}
              icon={
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 8.87528C16 8.12885 15.4 7.51814 14.6667 7.51814H7.76L8.64 3.20242L8.66667 2.89028C8.66667 2.46956 8.49333 2.08956 8.22667 1.80456L7.17333 0.732422L0.586667 7.43671C0.226667 7.80314 0 8.31885 0 8.87528V17.6967C0 18.8231 0.893333 19.7324 2 19.7324H11C11.8267 19.7324 12.5333 19.2167 12.84 18.4974L15.8533 11.3181C15.9467 11.0874 16 10.8296 16 10.5717V8.87528Z"
                    fill="white"
                  />
                </svg>
              }
            />
          </div>
          <GraphWrapper
            graphComonent={
              <Chart
                chartType="BubbleChart"
                width="100%"
                height="100%"
                data={landingPagePerformanceData}
                options={{
                  colors: ["#FFCFC6"],

                  bubble: {
                    textStyle: { fontSize: 12, bold: false, auraColor: "none" },
                    stroke: "none",
                  },
                  chartArea: { width: "82%", height: "76%" },
                  hAxis: { title: "Conversion Rate" },
                  vAxis: { title: "Bounce Rate" },
                  legend: "none",
                }}
              />
            }
            containerClassName={styles["graph-container"]}
            titleClassName={styles["sub-heading"]}
            title={
              <>
                Landing Page Performance <br />
                (Bounce Rate vs Conversion Rate)
              </>
            }
          />
        </div>
        <div className={styles["channel"]}>
          <TextElement className={styles["channel-title"]}>
            LinkedIn
          </TextElement>
          <div className={classNames(styles["channel-metrics"])}>
            <Metric
              label="Total LinkedIn Impressions"
              value={formatNumberStandard(
                keyFindings?.totalLinkedInImpressions
              )}
              className={styles["metric"]}
              iconContainerClassName={styles["icon-container"]}
              icon={
                <svg
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.0002 0.280273C5.0002 0.280273 1.5842 2.76827 0.200195 6.28027C1.5842 9.79227 5.0002 12.2803 9.0002 12.2803C13.0002 12.2803 16.4162 9.79227 17.8002 6.28027C16.4162 2.76827 13.0002 0.280273 9.0002 0.280273ZM9.0002 10.2803C6.7922 10.2803 5.0002 8.48827 5.0002 6.28027C5.0002 4.07227 6.7922 2.28027 9.0002 2.28027C11.2082 2.28027 13.0002 4.07227 13.0002 6.28027C13.0002 8.48827 11.2082 10.2803 9.0002 10.2803ZM9.0002 3.88027C7.6722 3.88027 6.6002 4.95227 6.6002 6.28027C6.6002 7.60827 7.6722 8.68027 9.0002 8.68027C10.3282 8.68027 11.4002 7.60827 11.4002 6.28027C11.4002 4.95227 10.3282 3.88027 9.0002 3.88027Z"
                    fill="white"
                  />
                </svg>
              }
            />
            <Metric
              className={styles["metric"]}
              label="Total LinkedIn Clicks"
              value={formatNumberStandard(keyFindings?.totalLinkedInClicks)}
              iconContainerClassName={styles["icon-container"]}
              icon={
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.78537 9.32266C3.57972 9.25987 2.56245 8.79519 1.73356 7.92863C0.904677 7.06206 0.490234 6.01968 0.490234 4.80147C0.490234 3.54558 0.929795 2.47808 1.80892 1.59895C2.68804 0.719834 3.75554 0.280273 5.01143 0.280273C6.22964 0.280273 7.27202 0.694716 8.13859 1.5236C9.00515 2.35249 9.46983 3.36976 9.53262 4.57541L7.9502 4.10445C7.78694 3.42627 7.43529 2.87054 6.89526 2.43726C6.35523 2.00398 5.72728 1.78734 5.01143 1.78734C4.18254 1.78734 3.47297 2.08247 2.8827 2.67274C2.29243 3.26301 1.9973 3.97258 1.9973 4.80147C1.9973 5.51732 2.21394 6.14527 2.64722 6.6853C3.0805 7.22533 3.63623 7.57698 4.31441 7.74024L4.78537 9.32266ZM8.21394 9.4922L7.64879 11.2065C7.586 11.3823 7.46669 11.4671 7.29086 11.4608C7.11504 11.4545 6.99573 11.3635 6.93293 11.1877L5.21865 5.47965C5.16841 5.3415 5.19981 5.21591 5.31284 5.10288C5.42587 4.98985 5.55146 4.95845 5.68961 5.00869L11.3976 6.72297C11.5734 6.78577 11.6645 6.90508 11.6708 7.0809C11.677 7.25673 11.5923 7.37604 11.4165 7.43883L9.70216 8.00398L12.1888 10.4906C12.4023 10.7041 12.5091 10.949 12.5091 11.2253C12.5091 11.5016 12.4023 11.7465 12.1888 11.96C11.9753 12.1735 11.7273 12.2803 11.4447 12.2803C11.1621 12.2803 10.9141 12.1735 10.7006 11.96L8.21394 9.4922Z"
                    fill="white"
                  />
                </svg>
              }
            />
          </div>
          <GraphWrapper
            graphComonent={
              <Chart
                chartType="LineChart"
                width="100%"
                height="100%"
                data={linkedInEngagementData}
                legendToggle
                options={{
                  curveType: "function",
                  colors: ["#02285E", "#FC8A73"],
                  chartArea: { width: "80%", height: "81%" },
                  legend: {
                    position: "top",
                  },
                }}
              />
            }
            containerClassName={classNames(
              styles["graph-container"],
              styles["line-container"]
            )}
            titleClassName={styles["sub-heading"]}
            title={
              <>
                LinkedIn Engagement Trends <br />
                (Impressions vs Clicks)
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MarketingDashboardReport;
