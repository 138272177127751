import React, { useEffect, useState } from "react";
import styles from "./RetentionCampaign.module.scss";
import useRiskProfile from "../../pages/CustomerRiskProfile/useRiskProfile";
import GeneratedActiveOffers from "../GeneratedActiveOffers/GeneratedActiveOffers";
import DottedLoader from "../DottedLoader/DottedLoader";
import TextElement from "../TextElement/TextElement";

const RetentionCampaign: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [loadingText, setLoadingText] = React.useState(
    "Generating Retention Offers"
  );

  const [displayedGenneratedOffers, setDisplayedGeneratedOffers] =
    useState(false);

  useEffect(() => {
    setLoading(true);
    setDisplayedGeneratedOffers(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const offers = [
    {
      heading: "Flexible Payment Option",
      text: "Extend your payment terms to 45 days to accommodate your financial needs",
      onClick: () => {},
    },
    {
      heading: "Referral Bonus (2 Months Free)",
      text: "Refer a client and get 2 months of free services.",
      onClick: () => {},
    },
    {
      heading: "Service Enhancement/Free Upgrade",
      text: "Get a free upgrade to our Premium Support tier for the next 3 months",
      onClick: () => {},
    },
    {
      heading: "Payment Discount",
      text: "Renew your contract with us today and enjoy a 10% discount on your monthly fees",
      onClick: () => {},
    },
  ];

  return (
    <div className={styles["container"]}>
      {loading && (
        <div className={styles["loading-container"]}>
          <TextElement>{loadingText}</TextElement>
          <DottedLoader />
        </div>
      )}
       {displayedGenneratedOffers && (
        <GeneratedActiveOffers
          offers={offers}
          loading={loading}
          description="We’ve generated these targeted retention offers to help keep your high-risk customers engaged. Pick the one you want to use for your campaign. "
          className={styles["generated-offers"]}
        />
      )} 
    </div>
  );
};

export default RetentionCampaign;
