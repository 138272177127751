import React, { useState } from "react";
import { MdPushPin } from "react-icons/md";

// Define the types for the props
interface CommonCardProps {
  title: string | React.ReactNode;
  content: string | React.ReactNode; // Content can be a string or JSX (React component)
  onPinClick?: (isPinned: boolean) => void; // More specific callback function type for onPinClick
}

const OverviewCard: React.FC<CommonCardProps> = ({
  title,
  content,
  onPinClick,
}) => {
  const [isPinned, setIsPinned] = useState<boolean>(false);

  const handlePinClick = () => {
    const newPinState = !isPinned;
    setIsPinned(newPinState);
    if (onPinClick) {
      onPinClick(newPinState);
    }
  };

  return (
    <div className="rounded-[10px] bg-white shadow-[5px_5px_3px_0px_rgba(0,0,0,0.05)] p-3 flex-1">
      <div className="p-3">
        <div className="flex items-center justify-between mb-3">
          <h4 className="text-blue-primary font-bold text-base">{title}</h4>
          <button onClick={handlePinClick}>
            <MdPushPin color={isPinned ? "red" : "black"} />
          </button>
        </div>
        {content}
      </div>
    </div>
  );
};

export default OverviewCard;
