import React, { useEffect, useState } from "react";

import styles from "./MarketSentimentCard.module.scss";
import Card, { CardType } from "../Card/Card";
import TextElement from "../TextElement/TextElement";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  MarketSentiment,
  updateCampaign,
} from "../../redux/campaignPinningSlice";
import BulbIcon from "../../icons/BulbIcon";

interface MarketSentimentCardProps {
  className?: string;
  dummyLoaderDuration?: number;
  cardType: CardType;
  marketSentiment: MarketSentiment;
}

const MarketSentimentCard: React.FC<MarketSentimentCardProps> = ({
  className,
  cardType,
  marketSentiment,
}) => {
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const handlePin = (isPinned: boolean) => {
    const id = marketSentiment?.id;
    if (!selectedCampaign?.includedData) return;
    let updatedMarketSentimentIds = [
      ...selectedCampaign?.includedData?.marketSentimentIds,
    ];
    if (isPinned) {
      updatedMarketSentimentIds.push(parseInt(id));
    } else if (id && updatedMarketSentimentIds.includes(parseInt(id))) {
      updatedMarketSentimentIds = updatedMarketSentimentIds.filter(
        (marketSentimentId) => marketSentimentId !== parseInt(id)
      );
    }
    dispatch(
      updateCampaign({
        ...selectedCampaign,
        includedData: {
          ...selectedCampaign.includedData,
          marketSentimentIds: Array.from(new Set(updatedMarketSentimentIds)),
        },
      })
    );
  };

  return (
    <Card
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      className={classNames(styles["container"], className)}
      type={cardType}
      pinIconClassName={styles["pin-icon"]}
      ellipsesIconClassName={styles["ellipses-icon"]}
    >
      <div className={classNames(styles["sentiment"])}>
        <div className={styles["header"]}>
          <BulbIcon />
          <TextElement className={styles["heading"]}>
            {marketSentiment?.area}
          </TextElement>
        </div>
        <ul>
          {marketSentiment?.insights.map((sentiment, index) => (
            <li key={index}>{sentiment}</li>
          ))}
        </ul>
      </div>
    </Card>
  );
};

export default MarketSentimentCard;
