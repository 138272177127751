import { useState, useCallback } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MiniMap,
  NodeTypes,
  Node,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import WidgetButton from "./components/WidgetButton";
import {
  ActionIcon,
  CheckIcon,
  ContentIcon,
  DelayIcon,
  EntryIcon,
  ExitIcon,
  ShareIcon,
  TriggerIcon,
} from "../../icons";
import HomeIcon from "../../icons/HomeIcon";
import EntryNode from "./components/EntryNode";
import ContentNode from "./components/ContentNode";
import TriggerNode from "./components/TriggerNode";
import ActionNode from "./components/ActionNode";
import ExitNode from "./components/ExitNode";
import DelayNode from "./components/DelayNode";
import CheckNode from "./components/CheckNode";

const initialNodes = [
  {
    id: "node-1",
    type: "entryNode",
    position: { x: 250, y: 100 },
    data: { label: "DroppedOffLeads", selected: false },
  },
  {
    id: "node-2",
    type: "contentNode",
    position: { x: 250, y: 200 },
    data: { label: "ReengageWithTrends", selected: false },
  },
  {
    id: "node-3",
    type: "actionNode",
    position: { x: 250, y: 300 },
    data: { label: "SendEmail", selected: false },
  },
  {
    id: "node-4",
    type: "triggerNode",
    position: { x: 250, y: 400 },
    data: { label: "CTA_Clicked", selected: false },
  },
  {
    id: "node-5",
    type: "checkNode",
    position: { x: 250, y: 500 },
    data: { label: "CTA_Clicked", selected: false },
  },
  {
    id: "node-6",
    type: "contentNode",
    position: { x: 250, y: 600 },
    data: { label: "HighlightSuccessStories", selected: false },
  },
  {
    id: "node-7",
    type: "actionNode",
    position: { x: 250, y: 700 },
    data: { label: "SendEmail", selected: false },
  },
  {
    id: "node-8",
    type: "triggerNode",
    position: { x: 250, y: 800 },
    data: { label: "CTA_Clicked", selected: false },
  },
  {
    id: "node-9",
    type: "checkNode",
    position: { x: 250, y: 900 },
    data: { label: "CTA_Clicked", selected: false },
  },
  {
    id: "node-10",
    type: "contentNode",
    position: { x: 250, y: 1000 },
    data: { label: "OfferConsultation", selected: false },
  },
  {
    id: "node-11",
    type: "actionNode",
    position: { x: 250, y: 1100 },
    data: { label: "SendEmail", selected: false },
  },
  {
    id: "node-12",
    type: "triggerNode",
    position: { x: 250, y: 1200 },
    data: { label: "CTA_Clicked", selected: false },
  },
  {
    id: "node-13",
    type: "checkNode",
    position: { x: 250, y: 1300 },
    data: { label: "MeetingScheduled", selected: false },
  },
  {
    id: "node-14",
    type: "contentNode",
    position: { x: 250, y: 1400 },
    data: { label: "EducationalWebinarInvite", selected: false },
  },
  {
    id: "node-15",
    type: "actionNode",
    position: { x: 250, y: 1500 },
    data: { label: "SendEmail", selected: false },
  },
  {
    id: "node-16",
    type: "triggerNode",
    position: { x: 250, y: 1600 },
    data: { label: "MeetingScheduled", selected: false },
  },
  {
    id: "node-17",
    type: "checkNode",
    position: { x: 250, y: 1700 },
    data: { label: "WebinarRegistered", selected: false },
  },
  {
    id: "node-18",
    type: "contentNode",
    position: { x: 250, y: 1800 },
    data: { label: "PostWebinarFollowUp", selected: false },
  },
  {
    id: "node-19",
    type: "actionNode",
    position: { x: 250, y: 1900 },
    data: { label: "SendEmail", selected: false },
  },
  {
    id: "node-20",
    type: "triggerNode",
    position: { x: 250, y: 2000 },
    data: { label: "WebinarCompleted", selected: false },
  },
  {
    id: "node-21",
    type: "checkNode",
    position: { x: 250, y: 2100 },
    data: { label: "MeetingScheduled", selected: false },
  },
  {
    id: "exit",
    type: "exitNode",
    position: { x: 250, y: 2200 },
    data: { label: "Exit", selected: false },
  },
];

const initialEdges = [
  { id: "edge-1-2", source: "node-1", target: "node-2" },
  { id: "edge-2-3", source: "node-2", target: "node-3" },
  { id: "edge-3-4", source: "node-3", target: "node-4" },
  { id: "edge-4-5", source: "node-4", target: "node-5" },
  { id: "edge-5-exit", source: "node-5", target: "exit" },
  { id: "edge-6-7", source: "node-6", target: "node-7" },
  { id: "edge-7-8", source: "node-7", target: "node-8" },
  { id: "edge-8-9", source: "node-8", target: "node-9" },
  { id: "edge-9-exit", source: "node-9", target: "exit" },
  { id: "edge-10-11", source: "node-10", target: "node-11" },
  { id: "edge-11-12", source: "node-11", target: "node-12" },
  { id: "edge-12-13", source: "node-12", target: "node-13" },
  { id: "edge-13-exit", source: "node-13", target: "exit" },
  { id: "edge-14-15", source: "node-14", target: "node-15" },
  { id: "edge-15-16", source: "node-15", target: "node-16" },
  { id: "edge-16-17", source: "node-16", target: "node-17" },
  { id: "edge-17-exit", source: "node-17", target: "exit" },
  { id: "edge-18-19", source: "node-18", target: "node-19" },
  { id: "edge-19-20", source: "node-19", target: "node-20" },
  { id: "edge-20-21", source: "node-20", target: "node-21" },
  { id: "edge-21-exit", source: "node-21", target: "exit" },
];

function ContentJourneyFive() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [lastNodePosition, setLastNodePosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 100,
    y: 100,
  });

  const nodeTypes: NodeTypes = {
    entryNode: EntryNode,
    contentNode: ContentNode,
    triggerNode: TriggerNode,
    actionNode: ActionNode,
    checkNode: CheckNode,
    exitNode: ExitNode,
    delayNode: DelayNode,
  };

  const onNodesChange = useCallback(
    (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    setSelectedNodeId(node.id);
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        data: {
          ...n.data,
          selected: n.id === node.id,
        },
      }))
    );
  };

  const addNode = (type: string, label: string) => {
    const newNodePosition = {
      x: lastNodePosition.x,
      y: lastNodePosition.y + 100,
    };

    const newNodeId = `${type}-${nodes.length + 1}`;
    const newNode = {
      id: newNodeId,
      type: type,
      position: newNodePosition,
      data: {
        label,
        selected: true,
        onDelete: (id: string) => {
          setNodes((nds) => nds.filter((node) => node.id !== id));
          setEdges((eds) =>
            eds.filter((edge) => edge.source !== id && edge.target !== id)
          );
          setSelectedNodeId(null);
        },
      },
    };

    setNodes((nds) =>
      nds.map((n) => ({ ...n, data: { ...n.data, selected: false } }))
    );
    setNodes((nds) => [...nds, newNode]);

    if (selectedNodeId) {
      const newEdge = {
        id: `edge-${selectedNodeId}-${newNodeId}`,
        source: selectedNodeId,
        target: newNodeId,
      };
      setEdges((eds) => [...eds, newEdge]);
    }

    setSelectedNodeId(newNodeId);
    setLastNodePosition(newNodePosition);
  };

  const deleteNode = () => {
    if (!selectedNodeId) return;

    setNodes((nds) => nds.filter((node) => node.id !== selectedNodeId));
    setEdges((eds) =>
      eds.filter(
        (edge) =>
          edge.source !== selectedNodeId && edge.target !== selectedNodeId
      )
    );
    setSelectedNodeId(null);
  };

  return (
    <div className="h-full">
      <div className="w-full bg-white py-5 px-4 border-b-2 flex items-center justify-between">
        <button className="flex items-center gap-2 hover:bg-gray-100 p-2 rounded-md">
          <HomeIcon />
          <span className="text-gray-700">Back</span>
        </button>
        <div className="flex items-center gap-3">
          <button className="p-2 rounded-md hover:bg-gray-100">
            <ShareIcon />
          </button>
          <button className="bg-blue-primary py-3 px-8 rounded-xl text-white hover:bg-blue-600 transition-colors duration-200">
            Share
          </button>
        </div>
      </div>

      <div className="flex h-[90vh]">
        <div className="h-full bg-white min-w-[180px] px-4">
          <div className="pt-5 pb-4">
            <h3 className="font-bold text-base">Widgets</h3>
          </div>

          <div className="gap-2 flex flex-col">
            <WidgetButton
              icon={<TriggerIcon />}
              onClick={() => addNode("triggerNode", "Trigger Node")}
            >
              Trigger
            </WidgetButton>
            <WidgetButton
              icon={<DelayIcon />}
              onClick={() => addNode("delayNode", "Delay Node")}
            >
              Delay
            </WidgetButton>
            <WidgetButton
              icon={<ContentIcon />}
              onClick={() => addNode("contentNode", "Content Node")}
            >
              Content
            </WidgetButton>
            <WidgetButton
              icon={<EntryIcon />}
              onClick={() => addNode("entryNode", "Entry Node")}
            >
              Entry
            </WidgetButton>
            <WidgetButton
              icon={<ExitIcon />}
              onClick={() => addNode("exitNode", "Exit Node")}
            >
              Exit
            </WidgetButton>
            <WidgetButton
              icon={<ActionIcon />}
              onClick={() => addNode("actionNode", "Action Node")}
            >
              Action
            </WidgetButton>
            <WidgetButton
              icon={<CheckIcon />}
              onClick={() => addNode("checkNode", "Check Node")}
            >
              Check
            </WidgetButton>
          </div>
          {/* Delete Node Button */}
          {selectedNodeId && (
            <button
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors duration-200"
              onClick={deleteNode}
            >
              Delete Selected Node
            </button>
          )}
        </div>

        <div className="h-full w-full">
          <ReactFlow
            nodes={nodes.map((node) => ({
              ...node,
              className: "rounded-md",
              style: {
                border: node.data.selected
                  ? "2px solid #02285E"
                  : "2px solid transparent",
                borderRadius: "5px",
              },
            }))}
            onNodesChange={onNodesChange}
            edges={edges}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            fitView
            onNodeClick={handleNodeClick}
          >
            <Background />
            <Controls />
            <MiniMap />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
}

export default ContentJourneyFive;
