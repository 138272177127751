import React, { useEffect } from "react";

import styles from "./CustomerSuccessDashbord.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { TextElement } from "../../componenets";
import { useAppDispatch } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import MarketingDashboardReport from "../../componenets/MarketingDashboardReport/MarketingDashboardReport";
import SalesDashboardReport from "../../componenets/SalesDashboardReport/SalesDashboardReport";
import CustomerSuccessReport from "../../componenets/CustomerSuccessReport/CustomerSuccessReport";

interface Props {}

const CustomerSuccessDashbord: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <MarkerterViewLayout
      header={
        <TextElement className={styles["header-text"]}>
          <button
            onClick={() => {
              navigate(ROUTE_DEFINITIONS.CUSTOMER_STAGE_INSIDE.path);
            }}
            className={styles["back-link"]}
          >
            Back to Main Funnel
          </button>  
          <br />
          Customer Success Dashboard
        </TextElement>
      }
      contentClassName={styles["content"]}
    >
      <CustomerSuccessReport className={styles["report"]} />
    </MarkerterViewLayout>
  );
};

export default CustomerSuccessDashbord;
