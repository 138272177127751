import { Handle, Position } from "@xyflow/react";
import { DelayIcon } from "../../../icons";
import { AiOutlineClose } from "react-icons/ai";

const DelayNode = ({ id, data }: any) => {
  return (
    <div className="relative px-4 py-2 shadow-md rounded-md bg-white border border-stone-400 group">
      <div className="flex justify-between gap-3 items-start">
        <div className="">
          <DelayIcon />
        </div>
        <div className="">
          <p className="m-0">Delay</p>
          <div className="text-gray-500">{data.label}</div>
        </div>
      </div>
      <button
        onClick={() => data.onDelete(id)}
        className="absolute top-[-10px] right-[-10px] p-1 shadow-md bg-white rounded-xl text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200 border border-stone-40"
      >
        <AiOutlineClose className="w-4 h-4" />
      </button>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default DelayNode;
