import React, { useEffect } from "react";
import styles from "./CustomerRetentionOffers.module.scss";
import CampaignHeader from "../../componenets/CampaignHeader/CampaignHeader";
import VerticalTabs from "../../componenets/VerticalTabs/VerticalTabs";
import MarketingBriefFormCard from "../../componenets/MarketingBriefFormCard/MarketingBriefFormCard";
import CampaignQueryChat from "../../componenets/CampaignQueryChat/CampaignQueryChat";
import { TextElement } from "../../componenets";
import classNames from "classnames";
import GenerateCampaignPlan from "../../componenets/GenerateCampaignPlan/GenerateCampaignPlan";
import RetentionCampaignHeader from "./components/CampaignHeader/RetentionCampaignHeader";
import useRiskProfile from "../CustomerRiskProfile/useRiskProfile";
import GeneratedActiveOffers from "../../componenets/GeneratedActiveOffers/GeneratedActiveOffers";
import RetentionCampaign from "../../componenets/RetentionCampaign/RetentionCampaign";

const CustomerRetentionOffers: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const [enableCampaignPlanGeneration, setEnableCampaignPlanGeneration] =
    React.useState(false);


  const tabs = [
    {
      id: 1,
      tabIcon: <img src="/gifs/ninja.gif" />,
      content: (
        <div className={classNames(styles["campaign-query-chat"])}>
          <CampaignQueryChat />
        </div>
      ),
    },
  ];

  return (
    <div className={styles["container"]}>
      <RetentionCampaignHeader className={styles["header"]} />
      <div
        className={classNames(
          styles["body"],
          isCollapsed ? styles["content-collapsed"] : styles["content-expanded"]
        )}
      >
        <VerticalTabs
          tabs={tabs}
          onCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
          className={styles["vertical-tabs"]}
          defaultActiveTab={null}
        />
        <div className={styles["model-response"]}>
          <TextElement className={styles["title"]}>
            Your RETENTION CAMPAIGN
          </TextElement>
          <div className={styles["plan"]}>
            <RetentionCampaign />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerRetentionOffers;
