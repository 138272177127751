import React from "react";

const ExitIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M9.3 13.3L6.7 10.7C6.51667 10.5167 6.425 10.2833 6.425 10C6.425 9.71667 6.51667 9.48333 6.7 9.3L9.325 6.675C9.50833 6.49167 9.73733 6.404 10.012 6.412C10.2873 6.42067 10.5167 6.51667 10.7 6.7C10.8833 6.88333 10.975 7.11667 10.975 7.4C10.975 7.68333 10.8833 7.91667 10.7 8.1L9.8 9H13.025C13.3083 9 13.5417 9.09567 13.725 9.287C13.9083 9.479 14 9.71667 14 10C14 10.2833 13.904 10.5207 13.712 10.712C13.5207 10.904 13.2833 11 13 11H9.8L10.725 11.925C10.9083 12.1083 10.996 12.3377 10.988 12.613C10.9793 12.8877 10.8833 13.1167 10.7 13.3C10.5167 13.4833 10.2833 13.575 10 13.575C9.71667 13.575 9.48333 13.4833 9.3 13.3ZM10 20C11.3833 20 12.6833 19.7373 13.9 19.212C15.1167 18.6873 16.175 17.975 17.075 17.075C17.975 16.175 18.6873 15.1167 19.212 13.9C19.7373 12.6833 20 11.3833 20 10C20 8.61667 19.7373 7.31667 19.212 6.1C18.6873 4.88333 17.975 3.825 17.075 2.925C16.175 2.025 15.1167 1.31233 13.9 0.787C12.6833 0.262333 11.3833 0 10 0C8.61667 0 7.31667 0.262333 6.1 0.787C4.88333 1.31233 3.825 2.025 2.925 2.925C2.025 3.825 1.31267 4.88333 0.788 6.1C0.262667 7.31667 0 8.61667 0 10C0 11.3833 0.262667 12.6833 0.788 13.9C1.31267 15.1167 2.025 16.175 2.925 17.075C3.825 17.975 4.88333 18.6873 6.1 19.212C7.31667 19.7373 8.61667 20 10 20Z" fill="#EB2701"/>
</svg>



    
  );
};

export default ExitIcon;
