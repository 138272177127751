import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  DateCapturedValues,
  OpportunityStage,
  KeyFindings,
  customerServiceInsights,
  SalesRevenueMetrics,
  MarketingCampaignEngagementMetrics,
  PaymentFinanceHealthMetrics,
  CustomerRiskData,
} from "./types";
import HttpService from "../../lib/api";
import { Product } from "../MarketingDashboardReport/types";

const useCustomerSuccessReport = (
  product: string,
  campaign: string,
  dateCaptured: DateCapturedValues
) => {
  const [products, setProducts] = useState<Product[]>([]);

  const dateCapturedOptions: { label: string; value: DateCapturedValues }[] = [
    { label: "Date Captured", value: "" },
    { label: "Last Month", value: "1 month" },
    { label: "Last 2 Months", value: "2 months" },
    { label: "Last 3 Months", value: "3 months" },
    { label: "Last 4 Months", value: "4 months" },
    { label: "Last 6 Months", value: "5 months" },
  ];

  const [keyFindings, setKeyFindings] = useState<KeyFindings>({
    totalTransactionValue: 0,
    averageTransactionValue: 0,
    customerLifetimeValue: 0,
    churnRate: 0,
    averageAccountHealthScore: 0,
    averageReputationScore: 0,
  });

  const [customerRiskData, setCustomerRiskData] = useState<CustomerRiskData>({
    newCustomers: 0,
    highRiskCustomers: 0,
    MediumLowRiskCustomers: 0,
  });

  const [salesRevenueMetrics, setSalesRevenueMetrics] =
    useState<SalesRevenueMetrics>({
      totalSales: 0,
      repeatPurchaseRate: 0,
    });

  const [
    marketingCampaignEngagementMetrics,
    setMarketingCampaignEngagementMetrics,
  ] = useState<MarketingCampaignEngagementMetrics>({
    campaignSuccessRate: 0,
    averageCustomerEngagementScore: 0,
  });

  const [paymentFinanceHealthMetrics, setPaymentFinanceHealthMetrics] =
    useState<PaymentFinanceHealthMetrics>({
      onTimePaymentRate: 0,
      paymentAlertRate: 0,
    });

  const [customerServiceInsights, setCustomerServiceInsights] =
    useState<customerServiceInsights>({
      totalNumbersOfIssues: 0,
      firstContactResolutionRate: 0,
      escalationRate: 0,
      averageSatisfactionResovledCases: 0,
      averageSatisfactionPendingCases: 0,
      averageSatisfactionEscalatedCases: 0,
    });

  const [avgAccountHealthScore, setAvgAccountHealthScore] = useState<
    (string | number)[][]
  >([]);
  const [churnRiskDistribution, setChurnRiskDistribution] = useState<
    (string | number)[][]
  >([]);
  const [avgReputationScore, setAvgReputationScore] = useState<
    (string | number)[][]
  >([]);
  const [paymentTrends, setPaymentTrends] = useState<(string | number)[][]>([]);
  const [customerEngagement, setCustomerEngagement] = useState<
    (string | number)[][]
  >([]);
  const [revenueTrends, setRevenueTrends] = useState<(string | number)[][]>([]);
  const [escalationRateByIssue, setEscalationRateByIssue] = useState<
    (string | number)[][]
  >([]);
  const [resolutionStatusDistribution, setResolutionStatusDistribution] =
    useState<(string | number)[][]>([]);
  const [issueCountByType, setIssueCountByType] = useState<
    (string | number)[][]
  >([]);

  const httpService = new HttpService();

  const executeRawQuery = async <T = any>(query: string): Promise<T> => {
    try {
      const response = await httpService.post<T, { query: string }>(
        "/api/execute-raw-sql/",
        { query }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to execute raw query", error);
      toast.error("Failed to execute raw query");
      return [] as T;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsResponse = await executeRawQuery<Product[]>(
          "SELECT * FROM public.product;"
        );
        setProducts(productsResponse);
      } catch (error) {
        console.error("Error fetching products", error);
        toast.error("Error fetching products");
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchKeyFindings = async () => {
      const response = await executeRawQuery<
        {
          totalTransactionValue: number;
          averageTransactionValue: number;
          churnRate: number;
          averageAccountHealthScore: number;
          averageReputationScore: number;
        }[]
      >(
        `
        SELECT 
    SUM(st.amount) AS total_transaction_value,
    AVG(st.amount) AS average_transaction_value,
    (SELECT COUNT(*) * 100.0 / (SELECT COUNT(*) FROM customer_profile) FROM customer_profile WHERE churn_risk = 'High') AS churn_rate,
    AVG(cp.account_health_score) AS average_account_health_score,
    AVG(cp.reputation_score) AS average_reputation_score
FROM 
    sales_transactions st
JOIN 
    customer_profile cp ON st.customer_id = cp.customer_id;

        `
      );

      const customerLifetimeValue = await executeRawQuery<{ avg: number }[]>(`
          WITH customer_summary AS (
    -- Summarize each customer's transaction data
    SELECT 
        customer_id AS customer_id,
        SUM(amount) AS total_purchase_amount,
        COUNT(transaction_id) AS total_transactions,
        MIN(EXTRACT(YEAR FROM purchase_date)) AS first_year,
        MAX(EXTRACT(YEAR FROM purchase_date)) AS last_year
    FROM 
        sales_transactions
    GROUP BY 
        customer_id
),

clv_calculation AS (
    -- Calculate APV and ACL, then compute CLV
    SELECT 
        customer_id,
        (total_purchase_amount * 1.0) / total_transactions AS apv,  -- Average Purchase Value
        (last_year - first_year + 1) AS acl,  -- Average Customer Lifespan in years
        ((total_purchase_amount * 1.0) / total_transactions) * (last_year - first_year + 1) AS clv_without_cac -- CLV without CAC
    FROM 
        customer_summary
)

-- Final select, including CAC as a placeholder (replace 'CAC_VALUE' with actual CAC if available)
SELECT 
    avg(clv_without_cac -  4000) -- Replace CAC_VALUE with the actual CAC or a calculated CAC if available
FROM 
    clv_calculation;
        `);

      setKeyFindings({
        ...response[0],
        customerLifetimeValue: customerLifetimeValue[0].avg,
      });
    };

    const fetchCustomerRiskData = async () => {
      const response = await executeRawQuery<
        {
          newCustomers: number;
          highRiskCustomers: number;
          mediumLowRiskCustomers: number;
        }[]
      >(
        `SELECT
    COUNT(CASE WHEN lifecycle_stage = 'New' THEN 1 END) AS new_customers,
    COUNT(CASE WHEN churn_risk = 'High' THEN 1 END) AS high_risk_customers,
    COUNT(CASE WHEN churn_risk IN ('Medium', 'Low') THEN 1 END) AS medium_low_risk_customers
FROM customer_profile;`
      );

      setCustomerRiskData({
        newCustomers: response[0].newCustomers,
        highRiskCustomers: response[0].highRiskCustomers,
        MediumLowRiskCustomers: response[0].mediumLowRiskCustomers,
      });
    };

    const fetchSalesRevenueMetrics = async () => {
      const response = await executeRawQuery<
        {
          totalSales: number;
          repeatPurchaseRate: number;
        }[]
      >(
        `
       WITH customer_purchase_counts AS (
    SELECT 
        customer_id,
        COUNT(transaction_id) AS purchase_count
    FROM 
        sales_transactions
    GROUP BY 
        customer_id
)

SELECT 
    SUM(amount) AS total_sales,
    AVG(purchase_count) AS repeat_purchase_rate  -- average purchase count per customer
FROM 
    sales_transactions st
JOIN 
    customer_purchase_counts cpc ON st.customer_id = cpc.customer_id;
        `
      );

      setSalesRevenueMetrics(response[0]);
    };

    const fetchMarketingCampaignEngagementMetrics = async () => {
      const response = await executeRawQuery<
        MarketingCampaignEngagementMetrics[]
      >(
        `
	SELECT 
    AVG(CASE WHEN resolution_provided = 'Resolved' THEN 1 ELSE 0 END) * 100 AS campaign_success_rate,
    AVG(satisfaction_score) AS average_customer_engagement_score
FROM 
    public.customer_service_interactions;
        `
      );

      setMarketingCampaignEngagementMetrics(response[0]);
    };

    const fetchPaymentFinanceHealthMetrics = async () => {
      const response = await executeRawQuery<
        {
          onTimePaymentRate: number;
          paymentAlertRate: number;
        }[]
      >(
        `SELECT 
            AVG(CASE WHEN payment_status = 'Paid' AND payment_date <= due_date THEN 1 ELSE 0 END) * 100 AS on_time_payment_rate,
            AVG(CASE WHEN payment_status = 'Pending' AND alerts_sent = 'Yes' THEN 1 ELSE 0 END) * 100 AS payment_alert_rate
        FROM payment_history`
      );
      setPaymentFinanceHealthMetrics(response[0]);
    };

    const fetchCustomerServiceInsights = async () => {
      const response = await executeRawQuery<customerServiceInsights[]>(
        `
         SELECT 
            COUNT(*) AS total_Numbers_Of_Issues,
            AVG(CASE WHEN first_contact_resolution = TRUE THEN 1 ELSE 0 END) * 100 AS first_contact_resolution_rate,
            AVG(CASE WHEN number_of_escalations > 0 THEN 1 ELSE 0 END) * 100 AS escalation_Rate,
            AVG(CASE WHEN resolution_provided = 'Resolved' THEN satisfaction_score ELSE NULL END) AS average_Satisfaction_Resovled_Cases,
            AVG(CASE WHEN resolution_provided = 'Pending' THEN satisfaction_score ELSE NULL END) AS average_Satisfaction_Pending_Cases,
            AVG(CASE WHEN number_of_escalations > 0 THEN satisfaction_score ELSE NULL END) AS average_Satisfaction_Escalated_Cases
        FROM customer_service_interactions;
        `
      );
      setCustomerServiceInsights(response[0]);
    };

    // Function to fetch Average Account Health Score by Industry Segment
    const getAvgAccountHealthScore = async () => {
      const query = `
      SELECT 
        i.industry_segment_name,
        AVG(cp.account_health_score) AS avg_account_health_score
      FROM customer_profile cp
      JOIN industry_segment i ON cp.industry_segment_id = i.industry_segment_id
      GROUP BY i.industry_segment_name;
    `;
      const result = await executeRawQuery<
        {
          industrySegmentName: string;
          avgAccountHealthScore: number;
        }[]
      >(query);
      setAvgAccountHealthScore([
        ["Industry Segment", "Average Account Health Score"],
        ...result.map((r) => [r.industrySegmentName, r.avgAccountHealthScore]),
      ]);
    };

    // Function to fetch Churn Risk Distribution
    const getChurnRiskDistribution = async () => {
      const query = `
      SELECT 
        cp.churn_risk,
        COUNT(*) AS customer_count
      FROM customer_profile cp
      GROUP BY cp.churn_risk;
    `;
      const result = await executeRawQuery<
        {
          churnRisk: string;
          customerCount: number;
        }[]
      >(query);
      setChurnRiskDistribution([
        ["Churn Risk", "Customer Count"],
        ...result.map((r) => [r.churnRisk, r.customerCount]),
      ]);
    };

    // Function to fetch Average Reputation Score by Segment
    const getAvgReputationScore = async () => {
      const query = `
      SELECT 
        i.industry_segment_name,
        AVG(cp.reputation_score) AS avg_reputation_score
      FROM customer_profile cp
      JOIN industry_segment i ON cp.industry_segment_id = i.industry_segment_id
      GROUP BY i.industry_segment_name;
    `;
      const result = await executeRawQuery<
        {
          industrySegmentName: string;
          avgReputationScore: number;
        }[]
      >(query);
      setAvgReputationScore([
        ["Industry Segment", "Average Reputation Score"],
        ...result.map((r) => [r.industrySegmentName, r.avgReputationScore]),
      ]);
    };

    const getPaymentTrends = async () => {
      const query = `
        SELECT 
          TO_CHAR(DATE_TRUNC('month', payment_date), 'Month') AS month,
          payment_status,
          COUNT(*) AS status_count
        FROM payment_history
        GROUP BY 
          TO_CHAR(DATE_TRUNC('month', payment_date), 'Month'),
          payment_status
        ORDER BY 
          month, 
          payment_status;
      `;
      const result = await executeRawQuery<
        {
          month: string;
          paymentStatus: string;
          statusCount: number;
        }[]
      >(query);

      console.log(result, "*********");

      // Initialize the data in the format for Google Charts
      const formattedData: (string | number)[][] = [
        ["Month", "Overdue", "Pending", "Paid"],
      ]; // Header row

      // Create a map to organize the results by month
      const monthMap: Record<string, Record<string, number>> = {};

      // Loop through the query result and populate the map
      result.forEach((r) => {
        if (!monthMap[r.month]) {
          monthMap[r.month] = {
            Overdue: 0, // Default 0 for missing statuses
            Pending: 0,
            Paid: 0,
          };
        }
        monthMap[r.month][r.paymentStatus] = r.statusCount; // Assign the count to the correct status
      });

      // Convert the map back to an array format
      Object.keys(monthMap).forEach((month) => {
        const dataRow = [
          month,
          monthMap[month]["Overdue"],
          monthMap[month]["Pending"],
          monthMap[month]["Paid"],
        ];
        formattedData.push(dataRow); // Add each month's data to the chart data
      });

      // Set the formatted data to state for use with Google Charts
      setPaymentTrends(formattedData);
    };

    const getCustomerEngagement = async () => {
      const query = `
      SELECT 
        cp.preferred_channel,
        COUNT(DISTINCT cp.customer_id) AS customer_count
      FROM customer_profile cp
      GROUP BY cp.preferred_channel;
    `;
      const result = await executeRawQuery<
        {
          preferredChannel: string;
          customerCount: number;
        }[]
      >(query);
      setCustomerEngagement([
        ["Preferred Channel", "Customer Count"],
        ...result.map((r) => [r.preferredChannel, r.customerCount]),
      ]);
    };

    const getRevenueTrends = async () => {
      const query = `
      SELECT 
        TO_CHAR(DATE_TRUNC('month', st.purchase_date), 'Mon') AS month,
        SUM(st.amount) AS total_revenue
      FROM sales_transactions st
      GROUP BY 
        DATE_TRUNC('month', st.purchase_date)
      ORDER BY 
        DATE_TRUNC('month', st.purchase_date);
    `;
      const result = await executeRawQuery<
        {
          month: string;
          totalRevenue: number;
        }[]
      >(query);
      setRevenueTrends([
        ["Month", "Total Revenue"],
        ...result.map((r) => [r.month, r.totalRevenue]),
      ]);
    };

    // Function to fetch Escalation Rate by Issue Type
    const getEscalationRateByIssue = async () => {
      const query = `
      SELECT 
        csi.issue_raised,
        COUNT(*) AS total_issues
      FROM customer_service_interactions csi
      GROUP BY csi.issue_raised;
    `;
      const result = await executeRawQuery<
        {
          issueRaised: string;
          totalIssues: number;
        }[]
      >(query);
      setEscalationRateByIssue([
        ["Issue Raised", "Total Issues"],
        ...result.map((r) => [r.issueRaised, r.totalIssues]),
      ]);
    };

    // Function to fetch Resolution Status Distribution
    const getResolutionStatusDistribution = async () => {
      const query = `
      SELECT 
        csi.resolution_provided,
        COUNT(*) AS issue_count
      FROM customer_service_interactions csi
      GROUP BY csi.resolution_provided;
    `;
      const result = await executeRawQuery<
        {
          resolutionProvided: string;
          issueCount: number;
        }[]
      >(query);
      setResolutionStatusDistribution([
        ["Resolution Provided", "Issue Count"],
        ...result.map((r) => [r.resolutionProvided, r.issueCount]),
      ]);
    };

    // Function to fetch Issue Count by Type
    const getIssueCountByType = async () => {
      const query = `
      SELECT 
        csi.issue_raised,
        COUNT(*) AS issue_count
      FROM customer_service_interactions csi
      GROUP BY csi.issue_raised;
    `;
      const result = await executeRawQuery<
        {
          issueRaised: string;
          issueCount: number;
        }[]
      >(query);
      setIssueCountByType([
        ["Issue Raised", "Issue Count"],
        ...result.map((r) => [r.issueRaised, r.issueCount]),
      ]);
    };

    try {
      fetchKeyFindings();
      fetchCustomerRiskData();
      fetchSalesRevenueMetrics();
      fetchMarketingCampaignEngagementMetrics();
      fetchPaymentFinanceHealthMetrics();
      fetchCustomerServiceInsights();

      getAvgAccountHealthScore();
      getChurnRiskDistribution();
      getAvgReputationScore();
      getPaymentTrends();
      getCustomerEngagement();
      getRevenueTrends();
      getEscalationRateByIssue();
      getResolutionStatusDistribution();
      getIssueCountByType();
    } catch (error) {
      console.error("Error fetching customer success report data", error);
      toast.error("Error fetching customer success report data");
    }
  }, []);

  return {
    products,
    dateCapturedOptions,
    keyFindings,
    customerRiskData,
    salesRevenueMetrics,
    marketingCampaignEngagementMetrics,
    paymentFinanceHealthMetrics,
    customerServiceInsights,

    avgAccountHealthScore,
    churnRiskDistribution,
    avgReputationScore,
    paymentTrends,
    customerEngagement,
    revenueTrends,
    escalationRateByIssue,
    resolutionStatusDistribution,
    issueCountByType,
  };
};

export default useCustomerSuccessReport;
