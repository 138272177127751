import React from "react";

const RetainCustomerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="151"
      height="105"
      viewBox="0 0 151 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M110.097 34.5614C110.097 34.5614 108.162 34.6479 107.607 34.9453C106.602 35.4838 105.285 37.4903 104.337 38.1248C101.438 40.0653 103.973 52.1307 103.973 52.1307L102.793 69.1902C102.793 69.1902 111.937 73.3686 118.749 73.0053C123.29 72.7632 136.795 70.9465 136.795 70.9465C136.795 70.9465 136.712 68.2969 136.553 67.4643C135.631 62.6314 131.146 53.8582 130.225 49.025C129.71 46.3244 130.891 40.5833 129.861 38.0341C129.776 37.8254 129.067 37.7426 128.983 37.5343C128.774 37.0216 129.316 35.3543 128.604 34.9304C127.071 34.017 122.775 33.6738 122.775 33.6738C122.775 33.6738 117.611 34.5565 115.962 34.6731C114.442 34.7807 110.097 34.5614 110.097 34.5614Z"
        fill="#02285E"
      />
      <path
        d="M124.358 42.1589C124.652 44.9622 122.526 47.1375 118.352 47.5757C114.179 48.0139 112.346 46.1399 112.052 43.3363C111.757 40.5327 113.59 38.7697 117.763 38.3315C121.937 37.8934 124.064 39.3556 124.358 42.1589Z"
        fill="#A53434"
        stroke="#02274C"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M85.3389 48.5355C85.3389 48.5355 82.5522 47.3883 81.5722 47.1879C80.5163 46.9723 77.7302 46.9584 77.2649 47.0004C76.5694 47.0627 75.1214 47.4929 74.665 48.0215C74.1798 48.5829 73.6807 49.6961 73.7707 50.3919C73.891 51.3319 74.6219 51.7999 75.0018 51.9655C75.7003 52.2702 78.0489 51.8913 78.0489 51.8913C78.0489 51.8913 74.139 51.9807 73.2484 52.8186C72.801 53.2389 72.6192 55.5626 72.7937 56.4642C72.9924 57.479 73.6075 59.5774 74.3503 60.2945C75.1167 61.0354 77.1838 62.029 78.2496 62.0197C79.7797 62.0065 82.8403 61.0135 84.0562 60.0845C84.9673 59.389 86.9707 56.5407 86.9707 56.5407L85.3389 48.5355Z"
        fill="#A53434"
      />
      <path
        d="M107.728 35.5355C106.723 36.0741 105.406 38.0805 104.458 38.7151C101.559 40.6556 94.7742 43.3207 91.4691 44.4375C87.7706 45.6872 84.5957 46.7841 84.5957 46.7841L86.8969 57.5024C86.8969 57.5024 90.038 56.9864 93.8306 55.9736C96.3732 55.2946 109.908 50.5992 109.908 50.5992C109.908 50.5992 108.284 35.2378 107.728 35.5355Z"
        fill="#02285E"
      />
      <path
        d="M130.407 38.291C130.407 38.291 127.727 42.015 127.136 45.3307C126.895 46.6856 127.313 49.5856 127.909 50.826C128.855 52.7976 132.269 55.8533 133.313 57.7749C134.722 60.3675 137.456 68.8006 137.456 68.8006C137.456 68.8006 140.989 68.993 143.487 67.8123C144.489 67.3383 147.047 66.6991 147.347 65.632C147.756 64.1787 146.368 59.3435 142.851 52.0978C138.31 42.742 130.407 38.291 130.407 38.291Z"
        fill="#02285E"
      />
      <path
        d="M102.823 69.1592C102.823 69.1592 103.497 80.5856 103.611 84.2679C103.837 91.6557 104.775 90.0639 104.927 92.0647C104.98 92.7506 104.469 94.3136 104.821 94.926C105.646 96.3577 109.591 96.5955 111.24 96.5002C112.559 96.424 116.3 97.0678 116.471 95.7583C116.918 92.337 117.278 87.3656 117.902 84.6299C118.285 82.9497 118.432 77.5969 120.082 78.09C121.096 78.3927 123.05 85.5589 123.776 88.1118C124.327 90.0513 125.578 95.1962 127.454 96.5141C128.853 97.4961 132.473 97.8756 134.176 97.7395C135.573 97.6286 139.649 98.0094 139.823 96.6191C140.126 94.197 137.548 86.739 137.047 83.3992C136.584 80.319 136.795 70.9434 136.795 70.9434C136.795 70.9434 124.438 71.412 119.871 71.2156C116.355 71.0669 102.823 69.1592 102.823 69.1592Z"
        fill="#02285E"
      />
      <path
        d="M131.87 71.1092C131.87 71.1092 132.446 81.9743 133.132 85.5721C133.688 88.4913 135.811 97.1536 135.811 97.1536L136.765 97.1181C136.765 97.1181 134.641 88.3777 134.085 85.436C133.412 81.8716 132.854 71.0791 132.854 71.0791L131.87 71.1092Z"
        fill="white"
      />
      <path
        d="M133.53 71.0497C133.53 71.0497 134.106 81.9131 134.792 85.5126C135.348 88.4318 137.479 97.0864 137.479 97.0864L138.42 97.0334C138.42 97.0334 136.3 88.3182 135.746 85.3751C135.073 81.8107 134.514 71.0186 134.514 71.0186L133.53 71.0497Z"
        fill="white"
      />
      <path
        d="M103.985 69.3213C103.985 69.3213 104.636 83.4556 105.306 88.1431C105.427 88.9907 106.022 90.8328 106.134 91.5141C106.32 92.6498 105.932 95.4905 105.932 95.4905L107.022 95.5713C107.022 95.5713 107.383 92.2841 107.244 91.0497C107.165 90.3492 106.672 88.8151 106.538 88.1229C105.662 83.5924 105.178 69.4842 105.178 69.4842L103.985 69.3213Z"
        fill="white"
      />
      <path
        d="M105.811 69.5713C105.811 69.5713 106.446 83.6503 107.116 88.3378C107.237 89.1857 107.818 90.6383 107.902 91.3236C107.99 92.0357 107.637 95.6229 107.637 95.6229L108.629 95.714C108.629 95.714 109.192 92.4791 109.054 91.2447C108.975 90.5439 108.481 89.0101 108.347 88.3176C107.471 83.7874 106.939 69.7173 106.939 69.7173L105.811 69.5713Z"
        fill="white"
      />
      <path
        d="M105.291 95.4258C105.291 95.4258 102.16 96.6224 100.306 97.7707C99.364 98.3536 96.2692 100.411 96.8611 102.187C97.8325 105.101 105.564 104.403 108.342 104.395C110.437 104.388 116.993 103.522 117.175 103.158C117.827 101.853 116.373 95.9713 116.373 95.9713C116.373 95.9713 111.977 95.909 110.514 95.8348C109.206 95.7682 105.291 95.4258 105.291 95.4258Z"
        fill="#02285E"
      />
      <path
        d="M128.201 96.9463C128.201 96.9463 121.591 100.332 122.474 103.954C122.846 105.481 129.854 104.943 132.189 104.748C136.428 104.395 140.394 104.405 141.286 103.07C142.024 101.966 139.555 97.1281 139.555 97.1281C139.555 97.1281 135.468 97.3739 134.105 97.3553C132.625 97.3348 128.201 96.9463 128.201 96.9463Z"
        fill="#02285E"
      />
      <path
        d="M137.192 11.4531C137.192 11.4531 141.482 14.5432 143.469 14.6449C145.457 14.7466 148.819 13.0445 148.819 13.0445L148.885 18.823C147.747 19.5223 146.476 19.9776 145.153 20.1601C143.829 20.3425 142.483 20.2481 141.198 19.8829C136.826 18.6402 136.846 14.7734 136.846 14.7734"
        fill="#C92B0C"
      />
      <path
        d="M98.7257 37.5775C94.654 31.5768 93.8491 18.9709 96.1874 13.4866C105.726 -4.35192 124.243 -1.81492 132.509 6.13158C141.359 14.6782 139.53 26.666 138.796 29.8591C138.036 33.1755 135.099 38.067 131.424 41.0173C128.653 43.241 118.904 45.3074 112.81 45.3643C106.903 45.4123 101.379 41.4664 98.7257 37.5775Z"
        fill="#02285E"
      />
      <path
        d="M113.477 21.4099C106.011 22.2157 103.191 18.6702 99.4935 21.1536C97.0095 22.8096 98.8741 33.0894 102.08 34.0595C106.611 35.429 106.904 32.9132 114.608 31.756C122.025 30.6408 126.797 35.3518 130.11 32.1644C132.535 29.8562 133.063 25.1015 132.447 22.0319C132.227 21.0358 131.687 20.139 130.91 19.4787C130.132 18.8184 129.16 18.4308 128.141 18.3751C124.587 18.1267 118.451 20.888 113.477 21.4099Z"
        fill="#A53434"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M137.959 15.4628L138.197 15.4704C138.197 15.4704 140.528 13.1613 141.907 12.7264C143.285 12.2915 149.002 11.9647 149.002 11.9647C149.002 11.9647 148.693 6.0908 147.078 4.87695C147.078 4.87695 145.712 7.99355 143.365 8.20089C141.019 8.40822 138.085 8.49069 137.036 11.7832"
        fill="#C92B0C"
      />
      <path
        d="M95.7028 15.0955C96.0672 14.9792 107.843 15.0471 113.714 14.5503C119.584 14.0535 135.712 10.9776 135.712 10.9776C135.712 10.9776 138.662 10.2251 139.385 12.6485C140.109 15.0719 138.727 15.4187 138.727 15.4187C119.846 20.5676 95.1378 19.3517 95.1378 19.3517C94.7854 17.8159 94.8957 15.3419 95.7028 15.0955Z"
        fill="#C92B0C"
      />
      <path
        d="M126.74 19.8281C126.74 19.8281 127.013 20.4173 126.38 22.9583C125.481 25.285 124.476 26.1103 122.12 26.5893C120.795 26.7691 119.447 26.6705 118.162 26.2998C116.877 25.9291 115.683 25.2945 114.658 24.4365L126.74 19.8281Z"
        fill="white"
      />
      <path
        d="M101.538 21.6309L112.169 24.4053C111.057 25.9335 109.501 27.0815 107.713 27.6932C106.909 28.0315 106.022 28.1198 105.167 27.9465C104.313 27.7732 103.53 27.3464 102.922 26.7217C102.231 25.8904 101.538 21.6309 101.538 21.6309Z"
        fill="white"
      />
      <path
        d="M137.456 68.8004C137.456 68.8004 137.133 71.0698 137.082 71.8315C137.016 72.8115 136.564 74.8998 137.038 75.7599C137.22 76.0911 137.943 76.4849 138.309 76.5776C138.751 76.6889 139.723 76.5018 140.126 76.7138C140.645 76.9867 140.972 78.3446 141.488 78.6211C142.667 79.2524 145.618 78.651 146.802 78.0309C147.396 77.7199 148.339 76.7303 148.891 76.3504C149.163 76.1633 149.864 76.0159 150.072 75.7599C150.549 75.1743 150.829 73.5531 150.708 72.8079C150.576 71.9948 149.859 70.4792 149.457 69.7609C148.917 68.7941 147.12 65.9961 147.12 65.9961C147.12 65.9961 143.795 67.5584 142.624 67.9035C141.366 68.2734 137.456 68.8004 137.456 68.8004Z"
        fill="#A53434"
      />
      <path
        d="M14.962 91.5322C13.154 95.7865 12.0325 100.167 14.2228 101.215C15.5767 101.863 33.5517 103.802 35.3607 101.362C36.6265 99.6546 34.632 97.2266 33.7709 96.1217C32.8919 94.9956 30.5122 93.0316 30.5122 93.0316C23.3166 93.386 15.4227 91.9221 14.962 91.5322Z"
        fill="#02285E"
      />
      <path
        d="M72.4443 49.7799C72.4443 49.7799 75.0591 48.4551 75.9937 48.1901C77.0009 47.9007 79.6992 47.6764 80.155 47.6817C80.8339 47.6897 82.27 47.997 82.7526 48.4766C83.2653 48.984 83.8336 50.025 83.8002 50.7066C83.7548 51.627 83.0814 52.1357 82.7257 52.3252C82.0716 52.6736 79.7658 52.4841 79.7658 52.4841C79.7658 52.4841 83.5624 52.2745 84.4894 53.0184C84.9547 53.392 85.3074 55.6306 85.2061 56.5178C85.0918 57.5164 84.653 59.5957 83.9873 60.3485C83.3007 61.1252 81.3708 62.2446 80.3381 62.3165C78.854 62.4195 75.8116 61.6872 74.5626 60.8801C73.6267 60.274 71.4702 57.6674 71.4702 57.6674L72.4443 49.7799Z"
        fill="#F2C5A7"
      />
      <path
        d="M48.6418 44.4161C50.2442 44.1671 54.8068 45.4594 58.1125 46.2195C61.8117 47.0704 73.0686 48.4982 73.0686 48.4982L71.7305 59.0781C71.7305 59.0781 60.5691 57.7513 56.8026 57.1389C54.2769 56.7285 47.5005 54.8867 46.4635 53.9839C45.3464 53.0125 44.1673 51.0922 44.8075 48.3803C45.4159 45.8049 48.1079 44.4993 48.6418 44.4161Z"
        fill="#02285E"
      />
      <path
        d="M54.3998 93.0601C55.139 87.3946 55.3291 85.4574 55.4748 82.7598C55.5577 81.226 55.6762 75.6178 55.251 65.6516C54.7873 54.8021 50.9934 46.8102 50.9934 46.8102C45.6279 42.4622 24.3261 42.189 22.6131 44.6905C20.4603 47.837 16.3017 68.5748 16.0185 71.3045C15.7354 74.0343 15.0799 92.0741 15.0799 92.0741C15.5406 92.4636 23.402 93.5033 30.5983 93.1489C32.4789 88.6479 35.9744 82.1315 35.9744 82.1315C35.9744 82.1315 38.3816 81.8238 38.5942 81.9301C38.8068 82.0364 40.7675 93.2363 40.7675 93.2363C40.7675 93.2363 48.3497 93.8871 54.3998 93.0601Z"
        fill="#02285E"
      />
      <path
        d="M55.2917 66.7402C55.2917 66.7402 49.0549 74.118 17.5494 63.1191L16.666 67.3585C16.666 67.3585 30.7156 73.5444 43.8908 73.0403C55.5404 71.1415 55.452 70.4645 55.452 70.4645"
        fill="#EB2701"
      />
      <path
        d="M42.9276 69.2832C42.9276 69.2832 41.7496 68.2763 40.3112 68.5651C38.8727 68.8539 38.6631 70.5669 38.6631 70.5669C38.6631 70.5669 36.7832 71.1299 35.5167 73.0052C34.2502 74.8804 34.0316 77.0107 30.3115 78.2898C30.3115 78.2898 31.2598 81.7482 33.4351 81.8115C33.4351 81.8115 36.1771 80.5937 37.1323 78.2789C38.0875 75.9641 38.9714 74.2356 39.9624 73.7699C39.9624 73.7699 42.4679 75.6316 44.1686 73.7279C44.1686 73.7279 45.5325 73.8017 46.9093 72.1649C46.9093 72.1649 47.3293 73.1072 45.8723 75.6866C44.4154 78.266 42.4755 79.9048 42.4755 79.9048C42.4755 79.9048 44.9887 80.3684 46.7202 80.3797C46.7202 80.3797 52.3791 74.789 47.8042 69.4223C47.8042 69.4223 48.3073 67.9872 46.7113 67.1446C45.1152 66.302 43.2817 68.2213 42.9276 69.2832Z"
        fill="#EB2701"
      />
      <path
        d="M59.3247 96.5345C58.3679 95.7191 54.3931 92.9648 54.3931 92.9648C48.3431 93.7928 40.7808 93.1709 40.7808 93.1709C40.1068 95.6522 39.4258 102.471 39.4967 102.496C40.3797 102.805 48.2822 103.064 51.3315 102.982C54.2607 102.903 61.7885 102.951 62.1949 101.789C63.1226 99.1398 60.2816 97.3499 59.3247 96.5345Z"
        fill="#02285E"
      />
      <path
        d="M46.1651 44.249C46.1651 44.249 41.0146 52.5512 38.5697 55.024C35.7303 57.8945 24.9683 65.4459 24.9683 65.4459L30.9739 66.9472C30.9739 66.9472 39.8435 60.1304 42.6322 57.4971C45.5994 54.6941 51.6409 44.8674 51.6409 44.8674L46.1651 44.249Z"
        fill="#012856"
      />
      <path
        d="M29.6001 45.2594L33.7805 44.749L40.434 52.8548L37.6913 55.9953L29.6001 45.2594Z"
        fill="#012856"
      />
      <path
        d="M33.0936 43.895L40.4244 52.8156L43.2506 49.0177L47.4015 43.542L33.0936 43.895Z"
        fill="#F2C5A7"
      />
      <path
        d="M14.3861 23.104C14.3861 23.104 15.6926 27.1095 11.5652 29.8545C7.43779 32.5995 3.2216 31.3032 3.2216 31.3032L1.37317 25.2571C1.37317 25.2571 5.43303 25.9079 7.46727 25.1458C9.5015 24.3837 12.9278 19.7363 12.9278 19.7363"
        fill="#EB2701"
      />
      <path
        d="M49.5043 46.9021C43.1601 48.8648 32.3415 49.9531 28.7281 48.5647C23.929 46.7186 19.2594 42.6091 17.3649 39.4166C15.5433 36.3408 9.66347 24.4672 16.0255 12.6778C21.9709 1.6597 40.3744 -7.09824 56.2002 8.26948C60.4422 13.1891 63.7953 26.5518 61.5501 34.1406C60.0925 39.0646 55.664 44.996 49.5043 46.9021Z"
        fill="#02285E"
      />
      <path
        d="M24.6294 23.9554C22.4491 24.8268 21.1286 26.9554 21.3671 29.1818C21.7314 32.5799 23.8743 37.3406 27.1578 38.9522C31.6622 41.1633 35.0553 34.6863 43.1525 33.3794C51.5362 32.0258 52.504 34.267 56.9305 31.6121C59.6096 30.0074 58.4398 18.2475 55.3314 17.3469C50.6708 15.9956 48.9144 20.6136 40.8874 22.2514C35.5289 23.3457 28.2428 22.5127 24.6294 23.9554Z"
        fill="#FFF6EB"
        stroke="#EB2701"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M13.2043 20.0587C11.0201 16.9835 7.9432 17.8728 5.43899 18.4378C2.93478 19.0028 0.477933 16.2188 0.477933 16.2188C-0.775 18.0072 0.831656 24.2182 0.831656 24.2182C0.831656 24.2182 6.87773 22.6616 8.42676 22.6493C9.97578 22.6371 13.1937 24.2683 13.1937 24.2683L13.4365 24.1788"
        fill="#EB2701"
      />
      <path
        d="M59.2413 14.0284C59.2413 14.0284 33.9743 23.5133 12.6519 24.4026C12.6519 24.4026 11.1025 24.5039 11.0472 21.749C10.9919 18.9941 14.3102 18.7891 14.3102 18.7891C14.3102 18.7891 32.0808 16.6237 38.365 15.1829C44.6492 13.7422 56.8434 9.7741 57.259 9.77344C58.1589 9.77046 59.0932 12.3025 59.2413 14.0284Z"
        fill="#EB2701"
      />
      <path
        d="M40.646 25.7675C39.0158 28.2916 36.4729 29.9956 33.608 30.4835C31.0004 30.7686 29.6842 30.2457 27.9802 28.1263C26.4779 25.696 26.5627 24.9932 26.5627 24.9932L40.646 25.7675Z"
        fill="#EB2701"
      />
      <path
        d="M53.5956 24.26C52.6749 26.0273 50.85 27.0461 48.9363 26.8586C46.8958 26.8209 44.9059 26.1476 43.2207 24.9178L53.3479 18.5117C53.3479 18.5117 54.057 23.1595 53.5956 24.26Z"
        fill="#EB2701"
      />
      <path
        d="M22.2401 44.7686C22.2401 44.7686 27.1224 47.1582 27.6964 50.3821C27.9308 51.6993 26.7359 54.541 26.1566 55.7475C25.2365 57.6645 21.9172 60.6354 20.9014 62.504C19.5322 65.0248 16.8734 73.2247 16.8734 73.2247C16.8734 73.2247 13.4385 73.4115 11.0111 72.2642C10.0367 71.8035 7.54907 71.1818 7.25728 70.1445C6.85984 68.7313 8.20915 64.0299 11.6291 56.9846C16.044 47.8868 22.2401 44.7686 22.2401 44.7686Z"
        fill="#012856"
      />
      <path
        d="M16.8734 73.2242C16.8734 73.2242 17.1877 75.4303 17.2377 76.1719C17.3016 77.1247 17.7411 79.155 17.2804 79.9916C17.1032 80.3135 16.4004 80.6964 16.044 80.7865C15.6135 80.8948 14.6696 80.7126 14.2774 80.919C13.7733 81.1839 13.455 82.5048 12.9526 82.7737C11.8073 83.3874 8.93747 82.8025 7.78588 82.1997C7.2086 81.8973 6.2915 80.9352 5.75462 80.5656C5.48966 80.3838 4.80905 80.2407 4.60635 79.9916C4.14267 79.4219 3.87042 77.8458 3.98833 77.1211C4.11684 76.3308 4.81401 74.857 5.20483 74.1578C5.73045 73.2179 7.47687 70.4971 7.47687 70.4971C7.47687 70.4971 10.7101 72.0163 11.8487 72.3518C13.0718 72.7118 16.8734 73.2242 16.8734 73.2242Z"
        fill="#F2C5A7"
      />
      <g opacity="0.25">
        <path
          d="M31.6218 77.7508C31.6218 77.7508 32.1686 79.6079 33.2523 79.8732C34.336 80.1384 35.4021 78.0635 36.1748 75.9865C36.9475 73.9096 38.581 72.8636 38.581 72.8636L38.7466 72.7487C38.7466 72.7487 38.3862 71.9396 38.3465 71.2076C38.3067 70.4757 38.5154 69.9441 38.5154 69.9441C38.5154 69.9441 39.1827 71.9992 41.2756 72.707C43.3685 73.4147 44.0971 72.3393 44.0971 72.3393C44.0971 72.3393 44.1418 71.9449 43.8653 71.3225C43.8653 71.3225 44.8105 71.517 45.8306 71.0741C46.8507 70.6313 47.844 68.8008 47.844 68.8008V69.5662L48.521 70.5084C48.521 70.5084 50.4254 73.0766 49.1983 76.3376C47.9712 79.5986 46.6073 80.4412 46.6073 80.4412L42.5464 79.8745L43.6394 78.869C43.6394 78.869 45.2812 78.7146 46.4665 77.8879C47.6519 77.0613 48.5037 74.4249 48.2076 73.1749C47.9116 71.925 46.9322 72.5397 46.9322 72.5397L46.3715 72.6765C46.3715 72.6765 46.1843 73.1316 45.254 73.482C44.3236 73.8324 44.165 73.7764 44.165 73.7764C44.165 73.7764 43.1353 74.6889 42.1924 74.6299C41.2494 74.571 40.0137 73.7175 40.0137 73.7175L39.5398 73.9625C39.5398 73.9625 38.831 74.3709 38.1077 75.9905C37.3843 77.6101 36.8637 79.6056 35.1563 80.724C33.7219 81.664 33.4192 81.8133 33.4192 81.8133C33.4192 81.8133 32.3219 81.663 31.4469 80.5475C30.5719 79.432 30.3576 78.3099 30.3576 78.3099L31.6218 77.7508Z"
          fill="#CE1B02"
        />
      </g>
      <path
        d="M85.1395 48.5806C85.1395 48.5806 82.3528 47.4334 81.3728 47.233C80.3169 47.017 77.5309 47.0035 77.0652 47.0452C76.3697 47.1078 74.922 47.538 74.4656 48.0666C73.9804 48.6297 73.4813 49.7408 73.5713 50.437C73.6916 51.377 74.4225 51.8446 74.8024 52.0106C75.5009 52.3153 83.261 52.4626 83.261 52.4626L85.1395 48.5806Z"
        fill="#A53434"
      />
    </svg>
  );
};

export default RetainCustomerIcon;
