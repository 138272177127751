import React, { useEffect } from "react";

import styles from "./MarketingCampaignDashboard.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { TextElement } from "../../componenets";
import { useAppDispatch } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import MarketingDashboardReport from "../../componenets/MarketingDashboardReport/MarketingDashboardReport";

interface Props {}

const MarketingCampaignDashboard: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <MarkerterViewLayout
      header={
        <TextElement className={styles["header-text"]}>
          <button
            onClick={() => {
              navigate(ROUTE_DEFINITIONS.CUSTOMER_STAGE_INSIDE.path);
            }}
            className={styles["back-link"]}
          >
            Back to Main Funnel
          </button>
          <br />
          Marketing Campaign Dashboard
        </TextElement>
      }
      contentClassName={styles["content"]}
    >
      <MarketingDashboardReport className={styles["report"]} />
    </MarkerterViewLayout>
  );
};

export default MarketingCampaignDashboard;
