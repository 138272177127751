import React from "react";
import styles from "./CreateCampaignPlan.module.scss";
import CampaignHeader from "../../componenets/CampaignHeader/CampaignHeader";
import VerticalTabs from "../../componenets/VerticalTabs/VerticalTabs";
import MarketingBriefFormCard from "../../componenets/MarketingBriefFormCard/MarketingBriefFormCard";
import CampaignQueryChat from "../../componenets/CampaignQueryChat/CampaignQueryChat";
import { TextElement } from "../../componenets";
import classNames from "classnames";
import GenerateCampaignPlan from "../../componenets/GenerateCampaignPlan/GenerateCampaignPlan";

const CreateCampaignPlan: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const [enableCampaignPlanGeneration, setEnableCampaignPlanGeneration] =
    React.useState(false);

  const tabs = [
    {
      id: 1,
      tabIcon: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5667 3.08333C15.5667 2.72435 15.2757 2.43333 14.9167 2.43333H12.25C11.891 2.43333 11.6 2.72435 11.6 3.08333V3.91667C11.6 4.27565 11.891 4.56667 12.25 4.56667H14.9167C15.2757 4.56667 15.5667 4.27565 15.5667 3.91667V3.08333ZM6.4 3.08333C6.4 2.72435 6.10898 2.43333 5.75 2.43333H3.08333C2.72435 2.43333 2.43333 2.72435 2.43333 3.08333V7.58333C2.43333 7.94232 2.72435 8.23333 3.08333 8.23333H5.75C6.10899 8.23333 6.4 7.94232 6.4 7.58333V3.08333ZM15.5667 10.4167C15.5667 10.0577 15.2757 9.76667 14.9167 9.76667H12.25C11.891 9.76667 11.6 10.0577 11.6 10.4167V14.9167C11.6 15.2757 11.891 15.5667 12.25 15.5667H14.9167C15.2757 15.5667 15.5667 15.2757 15.5667 14.9167V10.4167ZM6.4 14.0833C6.4 13.7243 6.10898 13.4333 5.75 13.4333H3.08333C2.72435 13.4333 2.43333 13.7243 2.43333 14.0833V14.9167C2.43333 15.2757 2.72435 15.5667 3.08333 15.5667H5.75C6.10899 15.5667 6.4 15.2757 6.4 14.9167V14.0833ZM15.25 0.9C16.2717 0.9 17.1 1.72827 17.1 2.75V4.25C17.1 5.27173 16.2717 6.1 15.25 6.1H11.9167C10.8949 6.1 10.0667 5.27173 10.0667 4.25V2.75C10.0667 1.72827 10.8949 0.9 11.9167 0.9H15.25ZM6.08333 0.9C7.10506 0.9 7.93333 1.72827 7.93333 2.75V7.91667C7.93333 8.93839 7.10506 9.76667 6.08333 9.76667H2.75C1.72827 9.76667 0.9 8.93839 0.9 7.91667V2.75C0.9 1.72827 1.72827 0.9 2.75 0.9H6.08333ZM15.25 8.23333C16.2717 8.23333 17.1 9.06161 17.1 10.0833V15.25C17.1 16.2717 16.2717 17.1 15.25 17.1H11.9167C10.8949 17.1 10.0667 16.2717 10.0667 15.25V10.0833C10.0667 9.06161 10.8949 8.23333 11.9167 8.23333H15.25ZM6.08333 11.9C7.10506 11.9 7.93333 12.7283 7.93333 13.75V15.25C7.93333 16.2717 7.10506 17.1 6.08333 17.1H2.75C1.72827 17.1 0.9 16.2717 0.9 15.25V13.75C0.9 12.7283 1.72827 11.9 2.75 11.9H6.08333Z"
            fill="white"
            stroke="white"
            stroke-width="0.3"
          />
        </svg>
      ),
      content: (
        <MarketingBriefFormCard
          className={styles["marketing-brief-form-card"]}
          onErrorMessage={() => {}}
          onSubmit={() => {
            setEnableCampaignPlanGeneration(true);
          }}
        />
      ),
    },
    {
      id: 2,
      tabIcon: <img src="/gifs/ninja.gif" />,
      content: (
        <div
          className={classNames(
            styles["campaign-query-chat"]
          )}
        >
          <CampaignQueryChat />
        </div>
      ),
    },
  ];

  return (
    <div className={styles["container"]}>
      <CampaignHeader className={styles["header"]} />
      <div
        className={classNames(
          styles["body"],
          isCollapsed ? styles["content-collapsed"] : styles["content-expanded"]
        )}
      >
        <VerticalTabs  
          tabs={tabs}
          onCollapse={(isCollapse) => setIsCollapsed(isCollapse)}
          className={styles["vertical-tabs"]}
        />
        <div className={styles["model-response"]}>
          <TextElement className={styles["title"]}>
            Your Campaign Plan
          </TextElement>
          <GenerateCampaignPlan
            enableCampaignPlanGeneration={enableCampaignPlanGeneration}
            className={styles["plan"]}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCampaignPlan;
   