import React, { useEffect, useState } from "react";

import styles from "./RetainCustomer.module.scss";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { Button, TextElement } from "../../componenets";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { useNavigate } from "react-router-dom";

import { UserRoleType } from "../../redux/authSlice";

import ApproverViewLayout from "../../componenets/ApproverViewLayout/ApproverViewLayout";
import RiskAnalysisCard from "../../componenets/RiskAnalysisCard/RiskAnalysisCard";
import DisplayResponseContainer from "../../componenets/CampaignReports/components/DisplayResponseContainer/DisplayResponseContainer";
import GeneratedActiveOffers from "../../componenets/GeneratedActiveOffers/GeneratedActiveOffers";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import Metric from "../../componenets/CampaignReports/components/Metric/Metric";
import Card from "../../componenets/Card/Card";
import HighRiskIcon from "../../icons/HighRiskIcon";

interface Props {
  // userRole: UserRoleType;
}

const RetainCustomer: React.FC<Props> = (props) => {
  // const { userRole } = props
  const navigate = useNavigate();

  const [displayGeneratedOffers, setDisplayGeneratedOffers] = useState(false);

  const handleGenerateOffers = (riskType: string) => {
    console.log(`Generate upsell offers for ${riskType} customers`);
  };

  const handleOfferContent = () => {};

  const offers = [
    {
      heading: "Service Enhancement/Free Upgrade",
      text: "Get a free upgrade to our Premium Support tier for the next 3 months",
      onClick: handleOfferContent,
    },
    {
      heading: "Flexible Payment Option",
      text: "Extend your payment terms to 45 days to accommodate your financial needs",
      onClick: handleOfferContent,
    },
    {
      heading: "Payment Discount",
      text: "Renew your contract with us today and enjoy a 10% discount on your monthly fees",
      onClick: handleOfferContent,
    },
    {
      heading: "Free Training",
      text: "Get a free training session on Salesforce best practices relevant to your business.",
      onClick: handleOfferContent,
    },
    {
      heading: "Service Enhancement/Free Upgrade",
      text: "Get a free upgrade to our Premium Support tier for the next 3 months",
      onClick: handleOfferContent,
    },
    {
      heading: "Flexible Payment Option",
      text: "Extend your payment terms to 45 days to accommodate your financial needs",
      onClick: handleOfferContent,
    },
    {
      heading: "Payment Discount",
      text: "Renew your contract with us today and enjoy a 10% discount on your monthly fees",
      onClick: handleOfferContent,
    },
    {
      heading: "Free Training",
      text: "Get a free training session on Salesforce best practices relevant to your business.",
      onClick: handleOfferContent,
    },
  ];

  const getUserLayoutWrapper = (children: React.ReactNode) => {
    return (
      <MarkerterViewLayout
        header={
          <TextElement className={styles["header-text"]}>
            <button
              onClick={() => {
                navigate(ROUTE_DEFINITIONS.CUSTOMER_STAGE_INSIDE.path);
              }}
              className={styles["back-link"]}
            >
              Back to Main Funnel
            </button>
            <br />
            Retention Health Overview
          </TextElement>
        }
      >
        {children}
      </MarkerterViewLayout>
    );
  };

  return getUserLayoutWrapper(
    <div className={styles["container"]}>
      <div className={styles["key-findings"]}>
        <TextElement className={styles["title"]}>
          Your Churn Metrics
        </TextElement>
        <div className={styles["findings"]}>
          <Metric
            label="Customer Churn Rate"
            value="50%"
            icon={
              <svg
                width="15"
                height="13"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 6.51777C9.57187 6.51777 11.25 5.0662 11.25 3.27402C11.25 1.48185 9.57187 0.0302734 7.5 0.0302734C5.42812 0.0302734 3.75 1.48185 3.75 3.27402C3.75 5.0662 5.42812 6.51777 7.5 6.51777ZM7.5 8.13965C4.99687 8.13965 0 9.22631 0 11.3834V13.0053H15V11.3834C15 9.22631 10.0031 8.13965 7.5 8.13965Z"
                  fill="white"
                />
              </svg>
            }
          />
          <Metric
            label="Revenue Churn Rate"
            value="302%"
            icon={
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7436 7.97005C19.9145 8.16241 20 8.39084 20 8.65534C20 8.91983 19.9145 9.14826 19.7436 9.34063L16.4336 12.731C16.2626 12.9234 16.0451 13.0196 15.7809 13.0196C15.5167 13.0196 15.2991 12.9234 15.1282 12.731L13.7995 11.3845C13.6131 11.1921 13.5198 10.9637 13.5198 10.6992C13.5198 10.4347 13.6131 10.2063 13.7995 10.0139C13.986 9.83756 14.2075 9.7494 14.4639 9.7494C14.7203 9.7494 14.9417 9.83756 15.1282 10.0139L15.7809 10.6872L18.4149 7.97005C18.6014 7.79371 18.8228 7.70555 19.0793 7.70555C19.3357 7.70555 19.5571 7.79371 19.7436 7.97005ZM8.69464 17.492L6.36364 15.3039C5.24476 14.2619 4.28516 13.3321 3.48485 12.5146C2.68454 11.6971 2.02409 10.9276 1.5035 10.2063C0.982906 9.4849 0.602176 8.78759 0.361305 8.11432C0.120435 7.44105 0 6.73572 0 5.99833C0 4.4915 0.48951 3.23714 1.46853 2.23525C2.44755 1.23336 3.66744 0.732422 5.1282 0.732422C5.93629 0.732422 6.70552 0.904746 7.4359 1.2494C8.16628 1.59404 8.79565 2.08697 9.32401 2.72818C9.85237 2.08697 10.4817 1.59404 11.2121 1.2494C11.9425 0.904746 12.7117 0.732422 13.5198 0.732422C14.8407 0.732422 15.9479 1.1452 16.8415 1.97075C17.735 2.79631 18.2906 3.76213 18.5082 4.86821C18.1974 4.73997 17.871 4.65981 17.5291 4.62775C17.1873 4.59569 16.8376 4.57966 16.4802 4.57966C15.1593 4.57966 13.9472 5.1287 12.8438 6.22676C11.7405 7.32483 11.1888 8.69942 11.1888 10.3505C11.1888 11.12 11.352 11.9014 11.6783 12.6949C12.0047 13.4884 12.4631 14.1336 13.0536 14.6306C12.7584 14.9031 12.3737 15.2518 11.8998 15.6766C11.4258 16.1014 11.0179 16.4741 10.676 16.7947L9.93007 17.492C9.75913 17.6523 9.55322 17.7324 9.31235 17.7324C9.07148 17.7324 8.86558 17.6523 8.69464 17.492Z"
                  fill="white"
                />
              </svg>
            }
          />
          <Metric
            label="Net Revenue Retention (NRR)"
            value="-29%"
            icon={
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 8.87528C16 8.12885 15.4 7.51814 14.6667 7.51814H7.76L8.64 3.20242L8.66667 2.89028C8.66667 2.46956 8.49333 2.08956 8.22667 1.80456L7.17333 0.732422L0.586667 7.43671C0.226667 7.80314 0 8.31885 0 8.87528V17.6967C0 18.8231 0.893333 19.7324 2 19.7324H11C11.8267 19.7324 12.5333 19.2167 12.84 18.4974L15.8533 11.3181C15.9467 11.0874 16 10.8296 16 10.5717V8.87528Z"
                  fill="white"
                />
              </svg>
            }
          />
          <Metric
            label="Customer Lifetime Value"
            value="$251,002"
            icon={
              <svg
                width="19"
                height="17"
                viewBox="0 0 19 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.04762 14.0658C9.04762 10.6258 11.8795 7.84353 15.381 7.84353C16.3581 7.84353 17.2719 8.06576 18.0952 8.44798V2.5102C18.0952 1.53242 17.281 0.732422 16.2857 0.732422H1.80952C0.814286 0.732422 0 1.53242 0 2.5102V13.1769C0 14.1546 0.814286 14.9546 1.80952 14.9546H9.12C9.07476 14.6613 9.04762 14.368 9.04762 14.0658ZM1.80952 2.5102L9.04762 6.95464L16.2857 2.5102V4.28798L9.04762 8.73242L1.80952 4.28798V2.5102ZM13.879 16.7324L10.6762 13.5858L11.9519 12.3324L13.87 14.2169L17.7062 10.448L19 11.7013L13.879 16.7324Z"
                  fill="white"
                />
              </svg>
            }
          />
          <Metric
            label="Average Customer Lifespan"
            value="2 years"
            icon={
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0L11 10.08L5.68333 10.53L8.70833 17.1L6.69167 18L3.75833 11.34L0 14.85V0Z"
                  fill="white"
                />
              </svg>
            }
          />
          <Metric
            label="Average Time to Churn"
            value="6.17 mos"
            icon={
              <svg
                width="18"
                height="23"
                viewBox="0 0 18 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.3 17.7574C1.58333 16.9574 1.02083 16.0408 0.6125 15.0074C0.204167 13.9741 0 12.8824 0 11.7324C0 9.23242 0.875 7.10742 2.625 5.35742C4.375 3.60742 6.5 2.73242 9 2.73242V0.732422L14 4.48242L9 8.23242V6.23242C7.48333 6.23242 6.1875 6.76992 5.1125 7.84492C4.0375 8.91992 3.5 10.2158 3.5 11.7324C3.5 12.4991 3.64583 13.2158 3.9375 13.8824C4.22917 14.5491 4.625 15.1324 5.125 15.6324L2.3 17.7574ZM9 22.7324L4 18.9824L9 15.2324V17.2324C10.5167 17.2324 11.8125 16.6949 12.8875 15.6199C13.9625 14.5449 14.5 13.2491 14.5 11.7324C14.5 10.9658 14.3542 10.2491 14.0625 9.58242C13.7708 8.91576 13.375 8.33242 12.875 7.83242L15.7 5.70742C16.4167 6.50742 16.9792 7.42409 17.3875 8.45742C17.7958 9.49076 18 10.5824 18 11.7324C18 14.2324 17.125 16.3574 15.375 18.1074C13.625 19.8574 11.5 20.7324 9 20.7324V22.7324Z"
                  fill="white"
                />
              </svg>
            }
          />
        </div>
      </div>
      <Card type="default">
        <div className={styles["high-risk-container"]}>
          <div className={styles["icon-container"]}>
            <HighRiskIcon />
            <div className={styles["risk-stats"]}>
              <TextElement className={styles["value"]}>125</TextElement>
              <TextElement className={styles["label"]}>
                High Risk Customers
              </TextElement>
            </div>
          </div>
          <div className={styles["overview-container"]}>
            <TextElement className={styles["overview-heading"]}>
              High Risk Customer Overview
            </TextElement>
            <div className={styles["overview-content"]}>
              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>
                  Contract Duration
                </TextElement>
                <TextElement className={styles["value"]}>
                  Mid-point (6-9 months) of a 12-month service contract.
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>
              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>
                  Service Engagement
                </TextElement>
                <TextElement className={styles["value"]}>
                  Fewer than 2 support/consultation activities per month.
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>
              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>
                  Satisfaction Scores
                </TextElement>
                <TextElement className={styles["value"]}>
                  CSAT of 60-70% and NPS of 3-4, showing dissatisfaction.
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>
              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>Delays</TextElement>
                <TextElement className={styles["value"]}>
                  1-2 months behind on project milestones.
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>

              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>
                  Support Resolution
                </TextElement>
                <TextElement className={styles["value"]}>
                  Slow ticket resolution (48-72 hours on average).
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>

              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>
                  Payment Issues
                </TextElement>
                <TextElement className={styles["value"]}>
                  1-2 late payments in the last 6 months.
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>

              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>
                  Communication
                </TextElement>
                <TextElement className={styles["value"]}>
                  Irregular participation in strategic meetings or
                  consultations.
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>

              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>
                  Upsell/Expansion
                </TextElement>
                <TextElement className={styles["value"]}>
                  No new service uptake or expansion for 6-12 months.
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>

              <div className={styles["overview-item"]}>
                <TextElement className={styles["label"]}>
                  Leadership Changes
                </TextElement>
                <TextElement className={styles["value"]}>
                  Recent changes in key client contacts or leadership.
                </TextElement>
              </div>
              <div className={styles["divider"]}></div>

              <Button
                className={styles["offers-btn"]}
                onClick={() => {
                  setDisplayGeneratedOffers(true);
                }}
              >
                Generate Rentention Offers
              </Button>
            </div>
          </div>  
        </div>
      </Card>
      {displayGeneratedOffers && <GeneratedActiveOffers 
        loading={false}
      offers={offers} />}
    </div>
  );
};

export default RetainCustomer;
