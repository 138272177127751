import { useState, useCallback } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MiniMap,
  NodeTypes,
  Node,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import WidgetButton from "./components/WidgetButton";
import {
  ActionIcon,
  CheckIcon,
  ContentIcon,
  DelayIcon,
  EntryIcon,
  ExitIcon,
  ShareIcon,
  TriggerIcon,
} from "../../icons";
import HomeIcon from "../../icons/HomeIcon";
import EntryNode from "./components/EntryNode";
import ContentNode from "./components/ContentNode";
import TriggerNode from "./components/TriggerNode";
import ActionNode from "./components/ActionNode";
import ExitNode from "./components/ExitNode";
import DelayNode from "./components/DelayNode";
import CheckNode from "./components/CheckNode";

const initialNodes = [
  {
    id: "node_1",
    type: "entryNode",
    data: {
      label: "DroppedOffLeads",
      selected: false,
    },
    position: {
      x: 250,
      y: 100,
    },
  },
  {
    id: "node_2",
    type: "contentNode",
    data: {
      label: "ReintroduceConsultingValue",
      selected: false,
    },
    position: {
      x: 250,
      y: 200,
    },
  },
  {
    id: "node_3",
    type: "actionNode",
    data: {
      label: "SendEmail",
      selected: false,
    },
    position: {
      x: 250,
      y: 300,
    },
  },
  {
    id: "node_4",
    type: "triggerNode",
    data: {
      label: "CTA_Clicked",
      selected: false,
    },
    position: {
      x: 250,
      y: 400,
    },
  },
  {
    id: "node_5",
    type: "checkNode",
    data: {
      label: "CTA_Clicked",
      selected: false,
    },
    position: {
      x: 250,
      y: 500,
    },
  },
  {
    id: "node_6",
    type: "contentNode",
    data: {
      label: "PresentTailoredSolutions",
      selected: false,
    },
    position: {
      x: 250,
      y: 600,
    },
  },
  {
    id: "node_7",
    type: "actionNode",
    data: {
      label: "SendEmail",
      selected: false,
    },
    position: {
      x: 250,
      y: 700,
    },
  },
  {
    id: "node_8",
    type: "triggerNode",
    data: {
      label: "CTA_Clicked",
      selected: false,
    },
    position: {
      x: 250,
      y: 800,
    },
  },
  {
    id: "node_9",
    type: "checkNode",
    data: {
      label: "ServicesExplored",
      selected: false,
    },
    position: {
      x: 250,
      y: 900,
    },
  },
  {
    id: "node_10",
    type: "contentNode",
    data: {
      label: "ClientSuccessShowcase",
      selected: false,
    },
    position: {
      x: 250,
      y: 1000,
    },
  },
  {
    id: "node_11",
    type: "actionNode",
    data: {
      label: "SendEmail",
      selected: false,
    },
    position: {
      x: 250,
      y: 1100,
    },
  },
  {
    id: "node_12",
    type: "triggerNode",
    data: {
      label: "ServicesExplored",
      selected: false,
    },
    position: {
      x: 250,
      y: 1200,
    },
  },
  {
    id: "node_13",
    type: "checkNode",
    data: {
      label: "SuccessStoriesViewed",
      selected: false,
    },
    position: {
      x: 250,
      y: 1300,
    },
  },
  {
    id: "node_14",
    type: "contentNode",
    data: {
      label: "InviteForWebinar",
      selected: false,
    },
    position: {
      x: 250,
      y: 1400,
    },
  },
  {
    id: "node_15",
    type: "actionNode",
    data: {
      label: "SendEmail",
      selected: false,
    },
    position: {
      x: 250,
      y: 1500,
    },
  },
  {
    id: "node_16",
    type: "triggerNode",
    data: {
      label: "SuccessStoriesViewed",
      selected: false,
    },
    position: {
      x: 250,
      y: 1600,
    },
  },
  {
    id: "node_17",
    type: "checkNode",
    data: {
      label: "WebinarRegistered",
      selected: false,
    },
    position: {
      x: 250,
      y: 1700,
    },
  },
  {
    id: "node_18",
    type: "contentNode",
    data: {
      label: "PersonalConsultationInvite",
      selected: false,
    },
    position: {
      x: 250,
      y: 1800,
    },
  },
  {
    id: "node_19",
    type: "actionNode",
    data: {
      label: "SendEmail",
      selected: false,
    },
    position: {
      x: 250,
      y: 1900,
    },
  },
  {
    id: "node_20",
    type: "triggerNode",
    data: {
      label: "WebinarRegistered",
      selected: false,
    },
    position: {
      x: 250,
      y: 2000,
    },
  },
  {
    id: "node_21",
    type: "checkNode",
    data: {
      label: "ConsultationScheduled",
      selected: false,
    },
    position: {
      x: 250,
      y: 2100,
    },
  },
  {
    id: "node_22",
    type: "contentNode",
    data: {
      label: "ShareExclusiveInsights",
      selected: false,
    },
    position: {
      x: 250,
      y: 2200,
    },
  },
  {
    id: "node_23",
    type: "actionNode",
    data: {
      label: "SendEmail",
      selected: false,
    },
    position: {
      x: 250,
      y: 2300,
    },
  },
  {
    id: "node_24",
    type: "triggerNode",
    data: {
      label: "ConsultationScheduled",
      selected: false,
    },
    position: {
      x: 250,
      y: 2400,
    },
  },
  {
    id: "node_25",
    type: "checkNode",
    data: {
      label: "ResourcesAccessed",
      selected: false,
    },
    position: {
      x: 250,
      y: 2500,
    },
  },
  {
    id: "node_26",
    type: "contentNode",
    data: {
      label: "FollowUpAfterConsultation",
      selected: false,
    },
    position: {
      x: 250,
      y: 2600,
    },
  },
  {
    id: "node_27",
    type: "actionNode",
    data: {
      label: "SendEmail",
      selected: false,
    },
    position: {
      x: 250,
      y: 2700,
    },
  },
  {
    id: "node_28",
    type: "triggerNode",
    data: {
      label: "ResourcesAccessed",
      selected: false,
    },
    position: {
      x: 250,
      y: 2800,
    },
  },
  {
    id: "node_29",
    type: "checkNode",
    data: {
      label: "NextStepsReviewed",
      selected: false,
    },
    position: {
      x: 250,
      y: 2900,
    },
  },
  {
    id: "node_30",
    type: "contentNode",
    data: {
      label: "OfferSpecialConsultationPackage",
      selected: false,
    },
    position: {
      x: 250,
      y: 3000,
    },
  },
  {
    id: "node_31",
    type: "actionNode",
    data: {
      label: "SendEmail",
      selected: false,
    },
    position: {
      x: 250,
      y: 3100,
    },
  },
  {
    id: "node_32",
    type: "triggerNode",
    data: {
      label: "NextStepsReviewed",
      selected: false,
    },
    position: {
      x: 250,
      y: 3200,
    },
  },
  {
    id: "node_33",
    type: "checkNode",
    data: {
      label: "SpecialOfferClaimed",
      selected: false,
    },
    position: {
      x: 250,
      y: 3300,
    },
  },
  {
    id: "exit",
    type: "exitNode",
    data: {
      label: "Exit",selected: false,
    },
    position: {
      x: 250,
      y: 3400,
    },
  },
];

const initialEdges = [
    {
        id: "edge_1_2",
        source: "node_1",
        target: "node_2",
        label: "Entry to Stage"
    },
    {
        id: "edge_0_2",
        source: "node_0",
        target: "node_2",
        label: "Entry to Stage"
    },
    {
        id: "edge_2_3",
        source: "node_2",
        target: "node_3",
        label: "Stage to Action"
    },
    {
        id: "edge_3_4",
        source: "node_3",
        target: "node_4",
        label: "Action to Trigger"
    },
    {
        id: "edge_4_5",
        source: "node_4",
        target: "node_5",
        label: "Trigger to Check"
    },
    {
        id: "edge_5_exit",
        source: "node_5",
        target: "exit",
        label: "Proceed to Next Stage"
    },
    {
        id: "edge_6_7",
        source: "node_6",
        target: "node_7",
        label: "Stage to Action"
    },
    {
        id: "edge_7_8",
        source: "node_7",
        target: "node_8",
        label: "Action to Trigger"
    },
    {
        id: "edge_8_9",
        source: "node_8",
        target: "node_9",
        label: "Trigger to Check"
    },
    {
        id: "edge_9_exit",
        source: "node_9",
        target: "exit",
        label: "Proceed to Next Stage"
    },
    {
        id: "edge_10_11",
        source: "node_10",
        target: "node_11",
        label: "Stage to Action"
    },
    {
        id: "edge_11_12",
        source: "node_11",
        target: "node_12",
        label: "Action to Trigger"
    },
    {
        id: "edge_12_13",
        source: "node_12",
        target: "node_13",
        label: "Trigger to Check"
    },
    {
        id: "edge_13_exit",
        source: "node_13",
        target: "exit",
        label: "Proceed to Next Stage"
    },
    {
        id: "edge_14_15",
        source: "node_14",
        target: "node_15",
        label: "Stage to Action"
    },
    {
        id: "edge_15_16",
        source: "node_15",
        target: "node_16",
        label: "Action to Trigger"
    },
    {
        id: "edge_16_17",
        source: "node_16",
        target: "node_17",
        label: "Trigger to Check"
    },
    {
        id: "edge_17_exit",
        source: "node_17",
        target: "exit",
        label: "Proceed to Next Stage"
    },
    {
        id: "edge_18_19",
        source: "node_18",
        target: "node_19",
        label: "Stage to Action"
    },
    {
        id: "edge_19_20",
        source: "node_19",
        target: "node_20",
        label: "Action to Trigger"
    },
    {
        id: "edge_20_21",
        source: "node_20",
        target: "node_21",
        label: "Trigger to Check"
    },
    {
        id: "edge_21_exit",
        source: "node_21",
        target: "exit",
        label: "Proceed to Next Stage"
    },
    {
        id: "edge_22_23",
        source: "node_22",
        target: "node_23",
        label: "Stage to Action"
    },
    {
        id: "edge_23_24",
        source: "node_23",
        target: "node_24",
        label: "Action to Trigger"
    },
    {
        id: "edge_24_25",
        source: "node_24",
        target: "node_25",
        label: "Trigger to Check"
    },
    {
        id: "edge_25_exit",
        source: "node_25",
        target: "exit",
        label: "Proceed to Next Stage"
    },
    {
        id: "edge_26_27",
        source: "node_26",
        target: "node_27",
        label: "Stage to Action"
    },
    {
        id: "edge_27_28",
        source: "node_27",
        target: "node_28",
        label: "Action to Trigger"
    },
    {
        id: "edge_28_29",
        source: "node_28",
        target: "node_29",
        label: "Trigger to Check"
    },
    {
        id: "edge_29_exit",
        source: "node_29",
        target: "exit",
        label: "Proceed to Next Stage"
    },
    {
        id: "edge_30_31",
        source: "node_30",
        target: "node_31",
        label: "Stage to Action"
    },
    {
        id: "edge_31_32",
        source: "node_31",
        target: "node_32",
        label: "Action to Trigger"
    },
    {
        id: "edge_32_33",
        source: "node_32",
        target: "node_33",
        label: "Trigger to Check"
    },
    {
        id: "edge_33_exit",
        source: "node_33",
        target: "exit",
    }
];

function ContentJourneyTen() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [lastNodePosition, setLastNodePosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 100,
    y: 100,
  });

  const nodeTypes: NodeTypes = {
    entryNode: EntryNode,
    contentNode: ContentNode,
    triggerNode: TriggerNode,
    actionNode: ActionNode,
    checkNode: CheckNode,
    exitNode: ExitNode,
    delayNode: DelayNode,
  };

  const onNodesChange = useCallback(
    (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    setSelectedNodeId(node.id);
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        data: {
          ...n.data,
          selected: n.id === node.id,
        },
      }))
    );
  };

  const addNode = (type: string, label: string) => {
    const newNodePosition = {
      x: lastNodePosition.x,
      y: lastNodePosition.y + 100,
    };

    const newNodeId = `${type}-${nodes.length + 1}`;
    const newNode = {
      id: newNodeId,
      type: type,
      position: newNodePosition,
      data: {
        label,
        selected: true,
        onDelete: (id: string) => {
          setNodes((nds) => nds.filter((node) => node.id !== id));
          setEdges((eds) =>
            eds.filter((edge) => edge.source !== id && edge.target !== id)
          );
          setSelectedNodeId(null);
        },
      },
    };

    setNodes((nds) =>
      nds.map((n) => ({ ...n, data: { ...n.data, selected: false } }))
    );
    setNodes((nds) => [...nds, newNode]);

    if (selectedNodeId) {
      const newEdge = {
        id: `edge-${selectedNodeId}-${newNodeId}`,
        source: selectedNodeId,
        target: newNodeId,
      };
      setEdges((eds) => [...eds, newEdge]);
    }

    setSelectedNodeId(newNodeId);
    setLastNodePosition(newNodePosition);
  };

  const deleteNode = () => {
    if (!selectedNodeId) return;

    setNodes((nds) => nds.filter((node) => node.id !== selectedNodeId));
    setEdges((eds) =>
      eds.filter(
        (edge) =>
          edge.source !== selectedNodeId && edge.target !== selectedNodeId
      )
    );
    setSelectedNodeId(null);
  };

  return (
    <div className="h-full">
      <div className="w-full bg-white py-5 px-4 border-b-2 flex items-center justify-between">
        <button className="flex items-center gap-2 hover:bg-gray-100 p-2 rounded-md">
          <HomeIcon />
          <span className="text-gray-700">Back</span>
        </button>
        <div className="flex items-center gap-3">
          <button className="p-2 rounded-md hover:bg-gray-100">
            <ShareIcon />
          </button>
          <button className="bg-blue-primary py-3 px-8 rounded-xl text-white hover:bg-blue-600 transition-colors duration-200">
            Share
          </button>
        </div>
      </div>

      <div className="flex h-[90vh]">
        <div className="h-full bg-white min-w-[180px] px-4">
          <div className="pt-5 pb-4">
            <h3 className="font-bold text-base">Widgets</h3>
          </div>

          <div className="gap-2 flex flex-col">
            <WidgetButton
              icon={<TriggerIcon />}
              onClick={() => addNode("triggerNode", "Trigger Node")}
            >
              Trigger
            </WidgetButton>
            <WidgetButton
              icon={<DelayIcon />}
              onClick={() => addNode("delayNode", "Delay Node")}
            >
              Delay
            </WidgetButton>
            <WidgetButton
              icon={<ContentIcon />}
              onClick={() => addNode("contentNode", "Content Node")}
            >
              Content
            </WidgetButton>
            <WidgetButton
              icon={<EntryIcon />}
              onClick={() => addNode("entryNode", "Entry Node")}
            >
              Entry
            </WidgetButton>
            <WidgetButton
              icon={<ExitIcon />}
              onClick={() => addNode("exitNode", "Exit Node")}
            >
              Exit
            </WidgetButton>
            <WidgetButton
              icon={<ActionIcon />}
              onClick={() => addNode("actionNode", "Action Node")}
            >
              Action
            </WidgetButton>
            <WidgetButton
              icon={<CheckIcon />}
              onClick={() => addNode("checkNode", "Check Node")}
            >
              Check
            </WidgetButton>
          </div>
          {/* Delete Node Button */}
          {selectedNodeId && (
            <button
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors duration-200"
              onClick={deleteNode}
            >
              Delete Selected Node
            </button>
          )}
        </div>

        <div className="h-full w-full">
          <ReactFlow
            nodes={nodes.map((node) => ({
              ...node,
              className: "rounded-md",
              style: {
                border: node.data.selected
                  ? "2px solid #02285E"
                  : "2px solid transparent",
                borderRadius: "5px",
              },
            }))}
            onNodesChange={onNodesChange}
            edges={edges}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            fitView
            onNodeClick={handleNodeClick}
          >
            <Background />
            <Controls />
            <MiniMap />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
}

export default ContentJourneyTen;
