import React, { ReactNode } from "react";
import { FaArrowRight, FaCheck } from "react-icons/fa";
import TwoStarIcon from "../../../icons/TwoStarIcon";
import { IoFlash } from "react-icons/io5";
import { CiAt } from "react-icons/ci";
import { HiOutlineMenuAlt2 } from "react-icons/hi";

type Props = {
  title: string;
  entryDescription: string;
  actionDescription: string;
  triggerDescription: string;
  checkContent?: ReactNode;
  subject: string;
  body: string;
};

const StageCard = ({
  title,
  entryDescription,
  actionDescription,
  triggerDescription,
  checkContent,
  subject = "",
  body = "",
}: Props) => {
  return (
    <div className="p-3 bg-white rounded-lg shadow-md border border-gray-200 mt-2">
      {/* Stage Header */}
      <div className="pb-2 rounded-t-lg">
        <h2 className="text-blue-primary font-bold">{title}</h2>
      </div>

      {/* Entry Section */}
      {entryDescription ? (
        <div className="">
          <div className="flex items-center">
            <div className="bg-green-500 border border-gray-300 flex items-center p-2 rounded-t-lg">
              <div className="bg-white p-1 rounded-full ">
                <FaArrowRight className="text-green-500 w-[12px] h-[12px]" />
              </div>

              <h3 className="text-white font-medium ml-2">Entry</h3>
            </div>
          </div>
          <div
            className="p-3"
            style={{ background: "rgba(56, 176, 90, 0.05)" }}
          >
            <p className="">{entryDescription}</p>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Action Section */}
      <div className="mt-2">
        <div className="flex items-center">
          <div className="bg-[#18A2F2] border border-gray-300 flex items-center p-2 rounded-t-lg">
            <div className="bg-white p-1 rounded-full">
              <TwoStarIcon
                className="text-[#18A2F2] w-[12px] h-[12px]"
                fill="#18A2F2"
              />
            </div>

            <h3 className="text-white font-medium ml-2">Action</h3>
          </div>
        </div>
        <div className="p-3" style={{ background: "rgba(56, 176, 90, 0.05)" }}>
          <p className="">{actionDescription}</p>

          <div className="bg-white rounded-lg p-[5px]">
            <div className="  flex items-center pb-2">
              <div className="bg-[#FFE5E0] p-1 rounded-lg ">
                <CiAt className="text-[#EB2701] w-[20px] h-[20px]" />
              </div>

              <h3 className="text-blue-primary font-normal ml-2">Subject</h3>
            </div>
            <div className="">
              <input
                value={subject}
                className="p-1 border rounded-md border-[rgba(163,179,201,0.5)] bg-white shadow-sm w-full"
                disabled
              />
            </div>

            <div className="  flex items-center py-2">
              <div className="bg-[#FFE5E0] p-1 rounded-lg ">
                <HiOutlineMenuAlt2 className="text-[#EB2701] w-[20px] h-[20px]" />
              </div>

              <h3 className="text-blue-primary font-normal ml-2">Body</h3>
            </div>
            <div className="">
              <textarea
                className="p-1 border rounded-md border-[rgba(163,179,201,0.5)] bg-white shadow-sm w-full"
                disabled
                rows={4}
                value={body}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Trigger Section */}
      {triggerDescription ? (
        <div className="mt-2">
          <div className="flex items-center">
            <div className="bg-[#FFA600] border border-gray-300 flex items-center p-2 rounded-t-lg">
              <div className="bg-white p-1 rounded-full">
                <IoFlash className="text-[#FFA600] w-[12px] h-[12px]" />
              </div>

              <h3 className="text-white font-medium ml-2">Trigger</h3>
            </div>
          </div>
          <div
            className="p-3"
            style={{ background: "rgba(255, 166, 0, 0.05)" }}
          >
            <p className="">{triggerDescription}</p>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Check Section */}
      {checkContent ? (
        <div className="mt-2">
          <div className="flex items-center">
            <div className="bg-[#EB6301] border border-gray-300 flex items-center p-2 rounded-t-lg">
              <div className="bg-white p-1 rounded-full">
                <FaCheck className="text-[#EB6301] w-[12px] h-[12px]" />
              </div>

              <h3 className="text-white font-medium ml-2">Check</h3>
            </div>
          </div>
          <div className="p-3" style={{ background: "rgba(235, 99, 1, 0.05)" }}>
            {checkContent}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default StageCard;
