// Button.tsx
import React, { ReactNode } from 'react';

interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  icon?: ReactNode;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

const WidgetButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  icon,
  className = '',
  type = 'button',
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`flex items-center gap-3 py-[10px] px-5 text-md font-normal text-gray-700 hover:bg-gray-50 rounded-xl w-full border ${className}`}
    >
      {icon}
      {children}
    </button>
  );
};

export default WidgetButton;
