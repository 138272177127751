import React from "react";

import styles from "./RecommendedProductOfferingsCard.module.scss";
import Card, { CardType } from "../Card/Card";
import classNames from "classnames";
import TextElement from "../TextElement/TextElement";
import WifiIcon from "../../icons/WifiIcon";
import IotIcon from "../../icons/IotIcon";
import SignalsIcon from "../../icons/SignalsIcon";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateCampaign } from "../../redux/campaignPinningSlice";
import { useCampaignAnalysisQueryChat } from "../../context/CampaignAnalysisQueryChatContext";

interface Props {
  className?: string;
  cardType: CardType;
}

const RecommendedProductOfferingsCard: React.FC<Props> = (props) => {
  const { className } = props;
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const { recommendation } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const handlePin = (isPinned: boolean) => {
    selectedCampaign?.includedData &&
      dispatch(
        updateCampaign({
          ...selectedCampaign,
          includedData: {
            ...selectedCampaign.includedData,
            recommendations: {
              ...selectedCampaign.includedData.recommendations,
              recommendedOfferings: isPinned,
            },
          },
        })
      );
  };

  return (
    <Card
      className={classNames(styles["container"], className)}
      onPin={() => handlePin(true)}
      unPin={() => handlePin(false)}
      type={props.cardType}
    >
      <TextElement className={styles["heading"]}>
        Recommended Product Offerings
      </TextElement>

      {recommendation?.recommendedProductOfferings &&
        Object.entries(recommendation?.recommendedProductOfferings).map(
          ([key, value], index) => {
            if (key !== "id")
              return (
                <div className={styles["offering"]} key={index}>
                  {/* <WifiIcon /> */}
                  <TextElement>
                    <span>{key}:</span> {value}
                  </TextElement>
                </div>
              );
          }
        )}
    </Card>
  );
};

export default RecommendedProductOfferingsCard;
