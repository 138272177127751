import { useState, useCallback } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MiniMap,
  NodeTypes,
  Node,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import WidgetButton from "./components/WidgetButton";
import {
  ActionIcon,
  CheckIcon,
  ContentIcon,
  DelayIcon,
  EntryIcon,
  ExitIcon,
  ShareIcon,
  TriggerIcon,
} from "../../icons";
import HomeIcon from "../../icons/HomeIcon";
import EntryNode from "./components/EntryNode";
import ContentNode from "./components/ContentNode";
import TriggerNode from "./components/TriggerNode";
import ActionNode from "./components/ActionNode";
import ExitNode from "./components/ExitNode";
import DelayNode from "./components/DelayNode";
import CheckNode from "./components/CheckNode";

const initialNodes = [
    {
      id: "node_1",
      type: "entryNode",
      position: { x: 250, y: 100 },
      data: { label: "Entry: DroppedOffLeads", selected: false },
    },
    {
      id: "node_2",
      type: "contentNode",
      position: { x: 250, y: 200 },
      data: { label: "Stage: ReintroduceCloudBenefits", selected: false },
    },
    {
      id: "node_3",
      type: "actionNode",
      position: { x: 250, y: 300 },
      data: { label: "Action: SendEmail", selected: false },
    },
    {
      id: "node_4",
      type: "triggerNode",
      position: { x: 250, y: 400 },
      data: { label: "Trigger: CTA_Clicked", selected: false },
    },
    {
      id: "node_5",
      type: "checkNode",
      position: { x: 250, y: 500 },
      data: { label: "Check: CTA_Clicked", selected: false },
    },
    {
      id: "node_6",
      type: "contentNode",
      position: { x: 250, y: 600 },
      data: { label: "Stage: ShowCostBenefits", selected: false },
    },
    {
      id: "node_7",
      type: "actionNode",
      position: { x: 250, y: 700 },
      data: { label: "Action: SendEmail", selected: false },
    },
    {
      id: "node_8",
      type: "triggerNode",
      position: { x: 250, y: 800 },
      data: { label: "Trigger: CTA_Clicked", selected: false },
    },
    {
      id: "node_9",
      type: "checkNode",
      position: { x: 250, y: 900 },
      data: { label: "Check: CTA_Clicked", selected: false },
    },
    {
      id: "node_10",
      type: "contentNode",
      position: { x: 250, y: 1000 },
      data: { label: "Stage: ShareClientTestimonials", selected: false },
    },
    {
      id: "node_11",
      type: "actionNode",
      position: { x: 250, y: 1100 },
      data: { label: "Action: SendEmail", selected: false },
    },
    {
      id: "node_12",
      type: "triggerNode",
      position: { x: 250, y: 1200 },
      data: { label: "Trigger: CTA_Clicked", selected: false },
    },
    {
      id: "node_13",
      type: "checkNode",
      position: { x: 250, y: 1300 },
      data: { label: "Check: CTA_Clicked", selected: false },
    },
    {
      id: "node_14",
      type: "contentNode",
      position: { x: 250, y: 1400 },
      data: { label: "Stage: InviteToWebinar", selected: false },
    },
    {
      id: "node_15",
      type: "actionNode",
      position: { x: 250, y: 1500 },
      data: { label: "Action: SendEmail", selected: false },
    },
    {
      id: "node_16",
      type: "triggerNode",
      position: { x: 250, y: 1600 },
      data: { label: "Trigger: CTA_Clicked", selected: false },
    },
    {
      id: "node_17",
      type: "checkNode",
      position: { x: 250, y: 1700 },
      data: { label: "Check: WebinarRegistered", selected: false },
    },
    {
      id: "node_18",
      type: "contentNode",
      position: { x: 250, y: 1800 },
      data: { label: "Stage: FollowUpAfterWebinar", selected: false },
    },
    {
      id: "node_19",
      type: "actionNode",
      position: { x: 250, y: 1900 },
      data: { label: "Action: SendEmail", selected: false },
    },
    {
      id: "node_20",
      type: "triggerNode",
      position: { x: 250, y: 2000 },
      data: { label: "Trigger: WebinarCompleted", selected: false },
    },
    {
      id: "node_21",
      type: "checkNode",
      position: { x: 250, y: 2100 },
      data: { label: "Check: MeetingScheduled", selected: false },
    },
    {
      id: "exit",
      type: "exitNode",
      position: { x: 250, y: 2200 },
      data: { label: "Exit", selected: false },
    },
  ];
  
  const initialEdges = [
    { id: "edge_1_2", source: "node_1", target: "node_2", label: "Entry to Stage" },
    { id: "edge_2_3", source: "node_2", target: "node_3", label: "Stage to Action" },
    { id: "edge_3_4", source: "node_3", target: "node_4", label: "Action to Trigger" },
    { id: "edge_4_5", source: "node_4", target: "node_5", label: "Trigger to Check" },
    { id: "edge_5_exit", source: "node_5", target: "exit", label: "Proceed to Next Stage" },
    { id: "edge_6_7", source: "node_6", target: "node_7", label: "Stage to Action" },
    { id: "edge_7_8", source: "node_7", target: "node_8", label: "Action to Trigger" },
    { id: "edge_8_9", source: "node_8", target: "node_9", label: "Trigger to Check" },
    { id: "edge_9_exit", source: "node_9", target: "exit", label: "Proceed to Next Stage" },
    { id: "edge_10_11", source: "node_10", target: "node_11", label: "Stage to Action" },
    { id: "edge_11_12", source: "node_11", target: "node_12", label: "Action to Trigger" },
    { id: "edge_12_13", source: "node_12", target: "node_13", label: "Trigger to Check" },
    { id: "edge_13_exit", source: "node_13", target: "exit", label: "Proceed to Next Stage" },
    { id: "edge_14_15", source: "node_14", target: "node_15", label: "Stage to Action" },
    { id: "edge_15_16", source: "node_15", target: "node_16", label: "Action to Trigger" },
    { id: "edge_16_17", source: "node_16", target: "node_17", label: "Trigger to Check" },
    { id: "edge_17_exit", source: "node_17", target: "exit", label: "Proceed to Next Stage" },
    { id: "edge_18_19", source: "node_18", target: "node_19", label: "Stage to Action" },
    { id: "edge_19_20", source: "node_19", target: "node_20", label: "Action to Trigger" },
    { id: "edge_20_21", source: "node_20", target: "node_21", label: "Trigger to Check" },
    { id: "edge_21_exit", source: "node_21", target: "exit" },
  ];
  
function ContentJourneySix() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [lastNodePosition, setLastNodePosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 100,
    y: 100,
  });

  const nodeTypes: NodeTypes = {
    entryNode: EntryNode,
    contentNode: ContentNode,
    triggerNode: TriggerNode,
    actionNode: ActionNode,
    checkNode: CheckNode,
    exitNode: ExitNode,
    delayNode: DelayNode,
  };

  const onNodesChange = useCallback(
    (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    setSelectedNodeId(node.id);
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        data: {
          ...n.data,
          selected: n.id === node.id,
        },
      }))
    );
  };

  const addNode = (type: string, label: string) => {
    const newNodePosition = {
      x: lastNodePosition.x,
      y: lastNodePosition.y + 100,
    };

    const newNodeId = `${type}-${nodes.length + 1}`;
    const newNode = {
      id: newNodeId,
      type: type,
      position: newNodePosition,
      data: {
        label,
        selected: true,
        onDelete: (id: string) => {
          setNodes((nds) => nds.filter((node) => node.id !== id));
          setEdges((eds) =>
            eds.filter((edge) => edge.source !== id && edge.target !== id)
          );
          setSelectedNodeId(null);
        },
      },
    };

    setNodes((nds) =>
      nds.map((n) => ({ ...n, data: { ...n.data, selected: false } }))
    );
    setNodes((nds) => [...nds, newNode]);

    if (selectedNodeId) {
      const newEdge = {
        id: `edge-${selectedNodeId}-${newNodeId}`,
        source: selectedNodeId,
        target: newNodeId,
      };
      setEdges((eds) => [...eds, newEdge]);
    }

    setSelectedNodeId(newNodeId);
    setLastNodePosition(newNodePosition);
  };

  const deleteNode = () => {
    if (!selectedNodeId) return;

    setNodes((nds) => nds.filter((node) => node.id !== selectedNodeId));
    setEdges((eds) =>
      eds.filter(
        (edge) =>
          edge.source !== selectedNodeId && edge.target !== selectedNodeId
      )
    );
    setSelectedNodeId(null);
  };

  return (
    <div className="h-full">
      <div className="w-full bg-white py-5 px-4 border-b-2 flex items-center justify-between">
        <button className="flex items-center gap-2 hover:bg-gray-100 p-2 rounded-md">
          <HomeIcon />
          <span className="text-gray-700">Back</span>
        </button>
        <div className="flex items-center gap-3">
          <button className="p-2 rounded-md hover:bg-gray-100">
            <ShareIcon />
          </button>
          <button className="bg-blue-primary py-3 px-8 rounded-xl text-white hover:bg-blue-600 transition-colors duration-200">
            Share
          </button>
        </div>
      </div>

      <div className="flex h-[90vh]">
        <div className="h-full bg-white min-w-[180px] px-4">
          <div className="pt-5 pb-4">
            <h3 className="font-bold text-base">Widgets</h3>
          </div>

          <div className="gap-2 flex flex-col">
            <WidgetButton
              icon={<TriggerIcon />}
              onClick={() => addNode("triggerNode", "Trigger Node")}
            >
              Trigger
            </WidgetButton>
            <WidgetButton
              icon={<DelayIcon />}
              onClick={() => addNode("delayNode", "Delay Node")}
            >
              Delay
            </WidgetButton>
            <WidgetButton
              icon={<ContentIcon />}
              onClick={() => addNode("contentNode", "Content Node")}
            >
              Content
            </WidgetButton>
            <WidgetButton
              icon={<EntryIcon />}
              onClick={() => addNode("entryNode", "Entry Node")}
            >
              Entry
            </WidgetButton>
            <WidgetButton
              icon={<ExitIcon />}
              onClick={() => addNode("exitNode", "Exit Node")}
            >
              Exit
            </WidgetButton>
            <WidgetButton
              icon={<ActionIcon />}
              onClick={() => addNode("actionNode", "Action Node")}
            >
              Action
            </WidgetButton>
            <WidgetButton
              icon={<CheckIcon />}
              onClick={() => addNode("checkNode", "Check Node")}
            >
              Check
            </WidgetButton>
          </div>
          {/* Delete Node Button */}
          {selectedNodeId && (
            <button
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors duration-200"
              onClick={deleteNode}
            >
              Delete Selected Node
            </button>
          )}
        </div>

        <div className="h-full w-full">
          <ReactFlow
            nodes={nodes.map((node) => ({
              ...node,
              className: "rounded-md",
              style: {
                border: node.data.selected
                  ? "2px solid #02285E"
                  : "2px solid transparent",
                borderRadius: "5px",
              },
            }))}
            onNodesChange={onNodesChange}
            edges={edges}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            fitView
            onNodeClick={handleNodeClick}
          >
            <Background />
            <Controls />
            <MiniMap />
          </ReactFlow>
        </div>
      </div>
    </div>
  );
}

export default ContentJourneySix;
