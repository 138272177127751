import { useState, useEffect } from "react";
import HttpService from "../../lib/api";
import axios from "axios";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useConnectingMarketingChannels = () => {
  const [loading, setLoading] = useState(false);

  const httpService = new HttpService();
  const navigate = useNavigate();

  const linkedInLogin = () => {
    setLoading(true);
    const redirectUrl = window.location.href;
    httpService
      .get<{
        authorizationUrl: string;
      }>(`api/linkedin/connect/?redirect_uri=${redirectUrl}`)
      .then((res) => {
        window.location.href = res.data.authorizationUrl;
      });
  };

  const sendAuthCodeToLinkedIn = (code: string) => {
    setLoading(true);
    httpService
      .get(
        `api/linkedin/callback/?code=${code}&redirect_uri=${
          window.location.origin + window.location.pathname
        }`
      )
      .then(() => {
        navigate(ROUTE_DEFINITIONS.MARKETING_CHANNEL_CONNECTIONS.path)
      })
      .catch(() => {
        toast.error("Failed to connect LinkedIn profile");
      })
      .finally(() => {
        navigate(ROUTE_DEFINITIONS.MARKETING_CHANNEL_CONNECTIONS.path);
      });
  };

  return {
    linkedInLogin,
    sendAuthCodeToLinkedIn, 
  };
};

export default useConnectingMarketingChannels;
