import React, { useState, useRef, useEffect, useCallback } from "react";

import styles from "./CampaignQueryChat.module.scss";

import SendIcon from "../../icons/SendIcon";
import ChatCard from "../ChatCard/ChatCard";

import classNames from "classnames";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useCampaignAnalysisQueryChat } from "../../context/CampaignAnalysisQueryChatContext";
import { SyncLoader } from "react-spinners";
import Input from "../CampaignReports/components/Input/Input";

import {
  fetchErikoAiAssistantResponse,
  updateErikoChatHistory,
} from "../../redux/erikoAiChatAsistantSlice";

import { CampaignQueryChatProps, Sender, Question } from "./types";
import IconicContainer from "../CampaignReports/components/IconicContainer/IconicContainer";
import TextElement from "../TextElement/TextElement";
import { NinjaIcon, PinIcon, UserIcon } from "../../icons/index copy";
import Download from "../../icons/Download";

const CampaignQueryChat: React.FC<CampaignQueryChatProps> = (props) => {
  const { className } = props;
  const [userInput, setUserInput] = useState("");
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);
  const selectedCampaignRef = useRef(selectedCampaign);

  const dispatch = useAppDispatch();

  const { conversation, updateConversation } = useCampaignAnalysisQueryChat();

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef<HTMLDivElement>(null);
  const { history } = useAppSelector((state) => state.erikoAiChatAsistant);

  const modelResponse = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );

  const modelResponseRef = useRef(modelResponse);

  useEffect(() => {
    modelResponseRef.current = modelResponse;
  }, [modelResponse]);

  useEffect(() => {
    if (loading) {
      loadingRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollTo(0, 0);
    }
  }, [loading]);

  const handleInsightsClick = async () => {
    if (!userInput.trim()) return;
    const query = userInput;
    setUserInput("");
    updateConversation([
      {
        sender: Sender.USER,
        content: query,
      },
    ]);
    setLoading(true);
    dispatch(updateErikoChatHistory(["Client", userInput]));

    dispatch(
      fetchErikoAiAssistantResponse({
        message: userInput,
        history,
        campaignPlanId: selectedCampaignRef.current?.id as string,
      })
    )
      .then((response) => {
        updateConversation([
          {
            sender: Sender.NINJA,
            content: (response.payload as string[])[1],
          },
        ]);
        setLoading(false);
        setUserInput("");
      })
      .catch((error) => {
        console.log(error);
        updateConversation([
          {
            sender: Sender.NINJA,
            content: "Something went wrong while fetching the response.",
          },
        ]);
        setLoading(false);
      });
  };

  const renderQuestion = (item: Question, index: number) => {
    return (
      <div
        className={classNames(
          styles["chat-message"],
          item.sender === Sender?.NINJA ? styles["left"] : styles["right"]
        )}
        key={index}
      >
        {item?.sender === Sender?.NINJA ? (
          <NinjaIcon className={styles["ninja-icon"]} />
        ) : (
          <UserIcon className={styles["user-icon"]} />
        )}
        <TextElement>{item.content}</TextElement>
      </div>
    );
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["chat"]}>
        {loading && (
          <div className={classNames(styles["chat-message"], styles["left"])}>
            <NinjaIcon className={styles["ninja-icon"]} />
            <SyncLoader color="#eb2701" />
          </div>
        )}
        {conversation?.map((item: any, index: number) => (
          <React.Fragment key={index}>
            {renderQuestion(item, index + 1)}
          </React.Fragment>
        ))}
      </div>
      <Input
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        placeholder="Send a message"
        className={styles["input-container"]}
        onSubmit={handleInsightsClick}
      />
    </div>
  );
};

export default CampaignQueryChat;
