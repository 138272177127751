import React, { useEffect } from "react";
import styles from "../ShowCampaignAnalysisData.module.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchSegments } from "../../../redux/campaignPlanAnalysisQueryChat";
import AudienceSegmentation from "../../AudienceSegmentationCards/AudienceSegmentation";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useCampaignAnalysisQueryChat } from "../../../context/CampaignAnalysisQueryChatContext";


interface ShowAudienceSegmentsProps {}

const ShowAudienceSegments: React.FC<ShowAudienceSegmentsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { selectedCampaign } = useAppSelector((state) => state.campaignData);

  const { audienceSegments } = useAppSelector(
    (state) => state.campaignPlanAnalysisQueryChat
  );
  const { conversation, clearConversation } = useCampaignAnalysisQueryChat();

  const [audienceSegmentationModalOpen, setAudienceSegmentationModalOpen] =
    React.useState(false);

  useEffect(() => {
    selectedCampaign &&
      dispatch(
        fetchSegments({
          campaignPlanId: selectedCampaign?.id,
        })
      ).catch(() => {
        toast.error("Error fetching segments");
        console.log("Error fetching segments");
      });
  }, []);

  return (
    <div
      className={classNames(
        styles["campaign-data"],
        styles["audience-segmentation"]
      )}
    >
      {selectedCampaign?.includedData?.audienceSegmentation?.map(
        (id, index) => {
          return (
            <AudienceSegmentation
              cardType="ellipses"
              segmentationId={id}
              key={index}
              className={styles["audience-segmentation-card"]}
            />
          );
        }
      )}
    </div>
  );
};

export default ShowAudienceSegments;
