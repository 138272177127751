
import React from "react";

const ShareIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20.8253 17.6707C19.8715 17.6707 19.0181 18.0472 18.3655 18.637L9.41717 13.4287C9.47992 13.1401 9.53012 12.8514 9.53012 12.5502C9.53012 12.249 9.47992 11.9603 9.41717 11.6717L18.2651 6.51355C18.9428 7.14106 19.8338 7.53012 20.8253 7.53012C22.9086 7.53012 24.5904 5.84839 24.5904 3.76506C24.5904 1.68173 22.9086 0 20.8253 0C18.742 0 17.0602 1.68173 17.0602 3.76506C17.0602 4.06626 17.1104 4.35492 17.1732 4.64357L8.3253 9.80171C7.64759 9.1742 6.75653 8.78514 5.76506 8.78514C3.68173 8.78514 2 10.4669 2 12.5502C2 14.6335 3.68173 16.3153 5.76506 16.3153C6.75653 16.3153 7.64759 15.9262 8.3253 15.2987L17.261 20.5196C17.1983 20.7831 17.1606 21.0592 17.1606 21.3353C17.1606 23.3559 18.8047 25 20.8253 25C22.8459 25 24.49 23.3559 24.49 21.3353C24.49 19.3148 22.8459 17.6707 20.8253 17.6707Z" fill="#EB2701"/>
    </svg>
    
    
  );
};

export default ShareIcon;
