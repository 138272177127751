import React from "react";
import styles from "./DriveConsumption.module.scss";

import CMOViewLayout from "../../componenets/CMOViewLayout/CMOViewLayout";
import { TextElement } from "../../componenets";
import MarketingReport from "../../componenets/CampaignReports/MarketingReport";
import MarkerterViewLayout from "../../componenets/MarkerterViewLayout/MarkerterViewLayout";
import { useNavigate } from "react-router-dom";
import { ROUTE_DEFINITIONS } from "../AppRoutes";
import DriveConsumptionReport from "../../componenets/CampaignReports/DriveConsumptionReport";

const DriveConsumption: React.FC = () => {
  const navigate = useNavigate();
  
  return (
    <MarkerterViewLayout
      header={
        <TextElement className={styles["header-text"]}>
          <button
            onClick={() => {
              navigate(ROUTE_DEFINITIONS.CUSTOMER_STAGE_INSIDE.path);
            }}
            className={styles["back-link"]}
          >  
            Back to Main Funnel
          </button>
          <br />
          Service Delivery Dashboard
        </TextElement>
      }
    >
      <DriveConsumptionReport className={styles["report"]} />
    </MarkerterViewLayout>
  );
};

export default DriveConsumption;
