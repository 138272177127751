import { v4 as uuidv4 } from "uuid";

export const formatNumberWithCommas = (num: number): string => {
  if (typeof num !== "number") return num;

  if (!num) return "0";
  const numString = num.toString();

  const groups = numString.split(/(?=(?:\d{3})+(?!\d))/);

  return groups.join(",");
};

export function checkPasswordStrength(password: string): string {
  const lengthRegex = /.{8,}/;
  const letterRegex = /[A-Za-z]/;
  const numberRegex = /\d/;
  const punctuationRegex = /[^\w\s]/;

  if (!lengthRegex.test(password)) {
    return "Password must be at least 8 characters long.";
  }
  if (!letterRegex.test(password)) {
    return "Password must include letters.";
  }
  if (!numberRegex.test(password)) {
    return "Password must include numbers.";
  }
  if (!punctuationRegex.test(password)) {
    return "Password must include punctuation marks.";
  }

  return "";
}

export function isObject(value: any): value is object {
  return value !== null && typeof value === "object";
}

export function isHttpResponseError(
  error: any
): error is { status: number; data: any } {
  return error && typeof error.status === "number" && isObject(error.data);
}

export const randomUUID = () => {
  return uuidv4();
};

export function formatNumberStandard(num: number): string {
  // Check if the number is a whole number
  if (num % 1 === 0) {
    // If it’s a whole number, format with commas and return without decimal places
    return num.toLocaleString();
  }

  // If it's a decimal, format to two decimal places and add commas
  let formattedNumber = num.toFixed(2);

  // Split the number into integer and decimal parts
  let [integerPart, decimalPart] = formattedNumber.split(".");

  // Add commas for thousands separator
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the integer part with the decimal part
  return `${integerWithCommas}.${decimalPart}`;
}
