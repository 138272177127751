
import React from "react";

const ActionIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect width="20" height="20" rx="10" fill="#18A2F2"/>
<path d="M8.7 14.5137L7.075 10.9671L3.5 9.35502L7.075 7.74292L8.7 4.19629L10.325 7.74292L13.9 9.35502L10.325 10.9671L8.7 14.5137ZM13.9 15.8034L13.0875 14.0301L11.3 13.2241L13.0875 12.418L13.9 10.6447L14.7125 12.418L16.5 13.2241L14.7125 14.0301L13.9 15.8034Z" fill="white"/>
</svg>



    
  );
};

export default ActionIcon;
