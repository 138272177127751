import React, { useEffect } from "react";

import styles from "./CreateStrategyBox.module.scss";

import classNames from "classnames";
import TextElement from "../../TextElement/TextElement";
import Button from "../../Button/Button";

interface Props {
  value: string | number;
  valueLabel: string;
  createBtnText: string;
  className?: string;
  icon?: React.ReactNode;
  iconContainerClassName?: string;
  onClick?: () => void;
}

const CreateStrategyBox: React.FC<Props> = ({
  value,
  valueLabel,
  createBtnText,
  className,
  icon,
  iconContainerClassName,
  onClick,
}) => {
  return (
    <div className={classNames(styles["container"], className)}>
      <div className={styles["content"]}>
        <span
          className={classNames(
            styles["icon-container"],
            iconContainerClassName
          )}
        >
          {icon}
        </span>
        <div className={styles["value-container"]}>
          <TextElement className={styles["value"]}>{value}</TextElement>
          <TextElement className={styles["label"]}>{valueLabel}</TextElement>
        </div>
      </div>
      <Button onClick={onClick} className={styles["create-btn"]}>
        {createBtnText}
      </Button>
    </div>
  );
};

export default CreateStrategyBox;
